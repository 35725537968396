import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Fragment } from "react";
import "@fontsource/open-sans";
import Layout from "./ui/Layout";
import TrickListPage from "./pages/TrickListPage";
import OneTrickPage from "./pages/OneTrickPage";
import About from "./pages/About";
import Home from './pages/Home';
import { GraphWithAnim } from "./components/GraphView";
import { PickAPattern } from "./components/PickPattern";
import PatternGenerator from "./gunswap/ui/PatternGenerator";
import PatternEditor from "./gunswap/ui/PatternEditor";
import Wrapper from "./components/animation/Wrapper";

function App() {
  // Renders component based on path
  return (
      <BrowserRouter>
        <Fragment>
          <Layout>
            <Routes>
              <Route path="/jugglemaster" element={<Wrapper />} />
              <Route path="/editor" element={<PatternEditor />} />
              <Route path="/generator" element={<PatternGenerator />} />
              <Route path="/pick" element={<PickAPattern />} />
              <Route path="/graph" element={<GraphWithAnim />} />
              <Route path="/about" element={<About />} />
              <Route path="/tricks/:trickId" element={<OneTrickPage />} />
              <Route path="/tricks" element={<TrickListPage />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </Layout>
        </Fragment>
      </BrowserRouter>
  );
}


export default App;
