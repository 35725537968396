import React, { createRef } from "react";
import JuggleMaster from "jmlib";
import { withTheme } from '@mui/styles';
import Paper from "@mui/material/Paper";
import Slider from "@mui/material/Slider";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = createRef();
    this.state = { text: "", expanded: false, fps: 24 };
  }
  getColor(i) {
    return this.colors[i % this.colors.length];
  }
  loop(jmlib, ctx) {
    jmlib.doJuggle();
    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    ctx.lineWidth = "1.0";
    ctx.strokeStyle = this.props.theme.palette.text.main;

    // draw head
    ctx.beginPath();
    ctx.moveTo(jmlib.ap.hx + jmlib.ap.hr, jmlib.ap.hy);
    ctx.arc(jmlib.ap.hx, jmlib.ap.hy, jmlib.ap.hr, 0, Math.PI * 2, false);
    ctx.closePath();
    ctx.stroke();

    // draw arms
    for (let i = 0; i < 5; i++) {
      ctx.beginPath();
      ctx.moveTo(jmlib.ap.rx[i], jmlib.ap.ry[i]);
      ctx.lineTo(jmlib.ap.rx[i + 1], jmlib.ap.ry[i + 1]);
      ctx.closePath();
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(jmlib.ap.lx[i], jmlib.ap.ly[i]);
      ctx.lineTo(jmlib.ap.lx[i + 1], jmlib.ap.ly[i + 1]);
      ctx.closePath();
      ctx.stroke();
    }

    // draw hands
    for (let i = 0; i < 10; i++) {
      ctx.beginPath();
      ctx.moveTo(
        jmlib.rhand.gx + jmlib.handpoly.rx[i],
        jmlib.rhand.gy + jmlib.handpoly.ry[i]
      );
      ctx.lineTo(
        jmlib.rhand.gx + jmlib.handpoly.rx[(i + 1) % 10],
        jmlib.rhand.gy + jmlib.handpoly.ry[(i + 1) % 10]
      );
      ctx.closePath();
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(
        jmlib.lhand.gx + jmlib.handpoly.lx[i],
        jmlib.lhand.gy + jmlib.handpoly.ly[i]
      );
      ctx.lineTo(
        jmlib.lhand.gx + jmlib.handpoly.lx[(i + 1) % 10],
        jmlib.lhand.gy + jmlib.handpoly.ly[(i + 1) % 10]
      );
      ctx.closePath();
      ctx.stroke();
    }

    // draw balls
    let r = jmlib.getBallRadius();
    if (r < 1) {
      r = 1;
    }
    for (let i = 0; i < jmlib.numBalls(); i++) {
      let ball = jmlib.b[i];

      ctx.beginPath();
      ctx.moveTo(ball.gx + r + r, ball.gy);
      ctx.arc(ball.gx + r, ball.gy + r, r, 0, Math.PI * 2, false);
      ctx.fillStyle = this.getColor(i);
      ctx.fill();
      ctx.closePath();
    }

    // show siteswap
    // this.show_siteonscreen(jmlib);
  }

  show_siteonscreen(jmlib) {
    var start = jmlib.getSiteposStart();
    var stop = jmlib.getSiteposStop();

    const highlightStyle = {
      color: this.props.theme.palette.primary.main,
      fontWeight: "normal",
    };
    let sitetext = (
      <div>
        <span> {jmlib.siteswap.substring(0, start)}</span>
        <b style={highlightStyle}>{jmlib.siteswap.substring(start, stop)} </b>
        <span>{jmlib.siteswap.substring(stop)}</span>
      </div>
    );

    this.setState({ text: sitetext });
  }

  componentDidMount() {
    // this.update();
    this.jm = new JuggleMaster();
    this.jmlib = this.jm.jmlib;
    this.validator = this.jm.validator;

    this.colors = [
      this.props.theme.palette.primary.main,
      this.props.theme.palette.secondary.light,
      this.props.theme.palette.grey[""],
    ];

    let site = this.props.site || "3";
    const style = this.props.style || "normal"; // What are the alternatives
    // Probably these are built in (could be useful when siteswap is just 3 but the trick is more complex)
    // pattern = args.hasOwnProperty('pattern') ? args.pattern : null,
    // pattNo  = args.hasOwnProperty('pattno') ? args.pattno : null;

    if (!this.validator.validateSite(site)) site = "0";

    this.canvas = this.canvasRef.current;
    this.ctx = this.canvas.getContext("2d");

    this.jmlib.setScalingMethod(this.jmlib.SCALING_METHOD_DYNAMIC);
    this.jmlib.setWindowSize(this.canvas.width, this.canvas.height);

    // jmlib.PatternLoader.loadPattern(jmlib, pattern);
    // jmlib.PatternLoader.loadPattern(jmlib, pattNo);
    this.jmlib.setPattern(
      site,
      site,
      this.jmlib.height_ratio,
      this.jmlib.dwell_ratio
    );

    this.jmlib.setStyleEx(style);
    this.jmlib.startJuggle();

    // this.handleSliderChange(null, 3);

    clearInterval(this.interval);
    this.interval = setInterval(
      () => this.loop(this.jmlib, this.ctx, this.canvas),
      1000 / this.state.fps
    );
    // this.jmlib = jmlib;
    // this.ctx = ctx;
    // this.canvas = canvas;
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  handleSliderChange(event, newValue) {
    this.setState({ fps: newValue });
    clearInterval(this.interval);
    this.interval = setInterval(
      () => this.loop(this.jmlib, this.ctx, this.canvas),
      1000 / newValue
    );
  }

  handleExpandClick = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  render() {
    if (this.jm) {
      console.log(this.props);

      let site = this.props.site || "3";
      const style = this.props.style || "normal"; // What are the alternatives
      if (!this.validator.validateSite(site)) site = "0";
      this.jmlib.setPattern(
        site,
        site,
        this.jmlib.height_ratio,
        this.jmlib.dwell_ratio
      );
      this.jmlib.setStyleEx(style);
      this.jmlib.startJuggle();
      clearInterval(this.interval);
      this.interval = setInterval(
        () => this.loop(this.jmlib, this.ctx, this.canvas),
        1000 / this.state.fps
      );
    }
    return (
      <Paper elevation={3}>
        <div>{this.state.text}</div>
        <div>{this.props.site}</div>
        <canvas ref={this.canvasRef}></canvas>
        <IconButton
          onClick={this.handleExpandClick}
          aria-expanded={this.state.expanded}
          aria-label="show more"
          size="large">
          <ExpandMoreIcon />
        </IconButton>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <Slider
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            marks
            min={1}
            step={4}
            max={120}
            onChange={this.handleSliderChange.bind(this)}
          />
        </Collapse>
      </Paper>
    );
  }
}

export default withTheme(Home);
