import SportsBaseballIcon from "@mui/icons-material/SportsBaseball";
import { Stack } from "@mui/material";

export function BallIndicator(props) {
    let container = [];
    for (let i = 0; i < props.value; i++) {
        container.push(<SportsBaseballIcon key={i} color="secondary" />);
    }
    return <Stack direction="row">{container}</Stack>;
}
