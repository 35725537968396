var styles = {};
styles["Normal"] = [ 1, [13, 0, 4, 0] ];
styles["Reverse"] = [ 1, [4, 0, 13, 0] ];
styles["Shower"] = [ 2, [5, 0, 10, 0, 10, 0, 5, 0] ];
styles["Center"] = [ 1, [13, 0, 0, 0] ];
styles["Mills Mess"] = [ 3, [-1, 0, -12, 0, 0, 0, 12, 0, 1, 0, -12, 0] ];
styles["Windmill"] = [ 2, [10, 0, -8, 0, -8, 0, 10, 0] ];
styles["Chop"] = [ 4, [4, 9, -10, -3, 10, 6, 7, 9, 10, 6, 7, 9, 4, 9, -10, -3] ];
styles["Eating Apples"] = [ 4, [0, 7, -3, -3, 12, 10, 0, 8, 12, 10, 0, 8, 0, 7, -3, -3] ];
styles["Eating an Apple"] = [ 8, [0, 6, -3, 4, 13, 0, 4, 0, 13, 0, 4, 0, 13, 0, 4, 0, 13, 0, 4, 0, 13, 0, 2, 0, 12, 7, 0, 7, 13, 0, -4, 0] ];
styles["Wide"] = [ 1, [21, 9, 18, 4] ];
styles["Tennis"] = [ 3, [8, 0, 4, 0, 8, 0, 4, 0, 14, 3, 14, 3] ];
styles["Over the Head"] = [ 1, [12, 19, 3, 19] ];
styles["Wide Reverse"] = [ 1, [-8, 0, 12, 0] ];
styles["Reachover"] = [ 8, [13, 0, 4, 0, 13, 0, 4, 0, 13, 0, 4, 0, 13, 0, 13, 0, 13, 0, 4, 0, 10, 0, 8, -2, -13, 5, 0, 2, 13, -3, 4, 0] ];
styles["Reachover (alternate)"] = [ 3, [10, 0, 3, -2, -13, 5, 13, 0, 13, -3, 4, 0] ];
styles["Reachunder"] = [ 8, [13, 0, 4, 0, 13, 0, 4, 0, 13, 0, 4, 0, 13, 0, 13, 0, 13, 0, 4, -3, 10, 4, 4, 4, -16, -3, 0, 0, 13, 3, 4, 0] ];
styles["Reachunder (alternate)"] = [ 3, [10, 4, 3, 4, -13, -5, 13, 0, 13, 3, 4, 0] ];
styles["Crossed Arm Reverse"] = [ 1, [-4, 0, -12, 0] ];
styles["1Up-2Up A"] = [ 4, [0, 0, 0, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0] ];
styles["1Up-2Up B"] = [ 8, [7, 0, 7, 0, 7, 0, 7, 0, 14, 0, 0, 0, 7, 0, 7, 0, 7, 0, 7, 0, 7, 0, 7, 0, 7, 0, 7, 0, 14, 0, 0, 0] ];
styles["1Up-2Up C"] = [ 16, [7, 0, 7, 0, 7, 0, 7, 0, 14, 0, 14, 0, 7, 0, 7, 0, 7, 0, 7, 0, 7, 0, 7, 0, 7, 0, 7, 0, 0, 0, 0, 0, 7, 0, 7, 0, 7, 0, 7, 0, 7, 0, 7, 0, 14, 0, 14, 0, 7, 0, 7, 0, 7, 0, 7, 0, 0, 0, 0, 0, 7, 0, 7, 0] ];
styles["1Up-2Up D"] = [ 8, [12, 0, -6, 0, -6, 0, 12, 0, 0, 0, 0, 0, 6, 0, 6, 0, -6, 0, 12, 0, 12, 0, -6, 0, 6, 0, 6, 0, 0, 0, 0, 0] ];
styles["1Up-2Up E"] = [ 4, [4, 0, 4, 0, 4, 0, 4, 0, 13, 0, -13, 0, 4, 0, 4, 0] ];
styles["1Up-2Up F"] = [ 6, [4, 0, 4, 0, 4, 0, 4, 0, 13, 7, 4, 0, 4, 0, 4, 0, 4, 0, 4, 0, 4, 0, 13, 7] ];
styles["Yo-Yo"] = [ 4, [5, 2, 5, 3, -5, -1, -5, 0, 5, 13, 5, 13, 5, -1, 5, 0] ];
styles["Oy-Oy"] = [ 4, [5, -1, 5, 0, -5, 3, -5, 4, 5, 10, 5, 10, 5, 3, 5, 4] ];
styles["Around The Yo-Yo"] = [ 4, [0, 2, 0, 3, 0, -1, 0, 0, 0, 13, 0, 13, 10, -1, -10, -1] ];
styles["Yo-Yo (Columns Fake A)"] = [ 4, [10, -1, 10, 0, 10, -1, 10, 0, 0, -1, 0, 0, 10, 10, 10, 10] ];
styles["Yo-Yo (Columns Fake B)"] = [ 4, [0, -1, 0, 0, 10, -1, 10, 0, 10, -1, 10, 0, 10, 10, 10, 10] ];
styles["Yo-Yo (Columns Fake C)"] = [ 8, [10, -1, 10, 0, 10, -1, 10, 0, 10, 10, 10, 10, 0, -1, 0, 0, 10, -1, 10, 0, 10, -1, 10, 0, 0, -1, 0, 0, 10, 10, 10, 10] ];
styles["Yo-Yo (Tornado)"] = [ 4, [5, 0, 5, 0, 15, 15, -15, 14, -5, 0, -5, 0, 15, 14, -15, 15] ];
styles["Yo-Yo (Traverse)"] = [ 4, [5, 0, 5, 0, 15, 12, 0, 12, -5, 0, -5, 0, -15, 12, 0, 12] ];
styles["Frankenstein"] = [ 4, [10, 10, 10, 0, 10, 10, 10, 0, 10, 9, 10, 9, 10, 9, 10, 9] ];
styles["Outside 441"] = [ 3, [4, 0, 13, 0, 4, 0, 13, 0, 4, 0, 0, 0] ];
styles["Arches"] = [ 6, [20, 10, 20, 10, 10, 0, 10, 0, 15, 5, 15, 5, 20, 10, 20, 10, 10, 0, 10, 0, 15, 5, 15, 5] ];
styles["2-in-1-Hand Tennis"] = [ 8, [5, 0, 5, 0, 5, 0, 5, 0, 15, 3, 15, 3, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 5, 0, 15, 3, 15, 3] ];
styles["Statue of Liberty A"] = [ 2, [3, 0, 12, 4, 10, 20, 9, 19] ];
styles["Statue of Liberty B"] = [ 2, [3, 4, 12, 0, 10, 17, 9, 19] ];
styles["Shuffle"] = [ 2, [0, -5, 12, 10, 10, 0, 14, 0] ];
styles["See Saw Shuffle"] = [ 4, [10, 0, 14, 10, 10, -3, 6, 0, 10, -3, 6, 0, 10, 0, 14, 10] ];
styles["441 Shuffle"] = [ 3, [5, -2, 5, -2, 6, 0, 6, 0, 15, 4, 9, 10] ];
styles["Both Side Slam"] = [ 8, [6, 0, 12, 10, 10, 0, 10, 0, 10, 0, 4, 0, 6, 0, 6, 0, 10, 0, 10, 0, 6, 0, 12, 10, 6, 0, 6, 0, 10, 0, 4, 0] ];
styles["Robot"] = [ 6, [5, 4, 10, -3, 10, 0, 10, 0, 10, -3, 10, 13, 0, 0, 0, 0, 10, 13, -10, 13, 6, 0, 6, 0] ];
styles["Exchange"] = [ 4, [0, 11, 10, 11, 10, 0, 0, 0, 0, 12, -10, 12, 10, 0, 0, 0] ];
styles["Carry"] = [ 2, [7, 12, -7, 12, 7, 0, -7, 0] ];
styles["Weave"] = [ 8, [10, 1, -7, 0, 12, 0, 12, 9, 5, 0, 5, 0, -5, 9, 0, 3, 12, 0, 12, 9, 10, 1, -7, 0, -5, 9, 0, 3, 5, 0, 5, 0] ];
styles["Follow"] = [ 3, [-10, 10, -10, 4, 10, -3, 10, 10, 0, 4, 0, 2] ];
styles["Burke's Barrage"] = [ 3, [12, 12, -6, -2, -6, 5, -6, 12, 0, 5, 0, 5] ];
styles["Rubenstein's Revenge"] = [ 5, [3, 1, 13, 8, 7, -2, -10, -3, 3, 6, -12, 2, -1, 0, 12, 2, -2, 2, -10, -2] ];
styles["4-Columns Switch"] = [ 4, [12, 0, 12, 2, 12, 2, 12, 0, 4, 0, 4, 0, 4, 0, 4, 0] ];
styles["4-ball Cross A"] = [ 2, [13, 0, 7, 3, 13, 3, 7, 0] ];
styles["4-ball Cross B"] = [ 4, [13, 0, 7, 3, 13, 3, 7, 0, 13, 3, 7, 0, 13, 0, 7, 3] ];
styles["4-ball Tennis"] = [ 5, [9, 0, 4, 0, 9, 0, 4, 0, 0, 0, 4, 0, 15, 3, 15, 3, 9, 0, 4, 0] ];
styles["4-ball Burke's Barrage"] = [ 3, [14, 14, -5, -2, -9, 3, -9, 14, 0, 0, 3, 0] ];
styles["5-ball Burke's Barrage"] = [ 3, [16, 14, -3, -4, -9, 0, -10, 14, 2, -2, 4, 0] ];
styles["Odd Half"] = [ 2, [8, 0, 15, 8, 15, 8, 8, 0] ];
styles["3-High-Low Shower B"] = [ 4, [6, 0, 12, 3, 6, 3, 6, 0, 6, 0, 6, 3, 12, 3, 6, 0] ];
styles["Under-the-Hand Shower"] = [ 2, [5, 0, -10, 0, 3, 4, -3, 4] ];
styles["Under-the-Hand Shower 2"] = [ 4, [3, 0, 10, 0, 5, 4, 0, 4, 3, 0, -10, 0, 5, 4, 0, 4] ];
styles["High Half Shower"] = [ 2, [7, 7, -7, 7, 13, 0, -13, 0] ];
styles["False Shower"] = [ 5, [10, 0, -10, -2, -10, 4, 10, 2, -1, 0, -12, 0, 0, 0, 12, 0, 1, 0, -12, 0] ];
styles["No Through Mills Mess"] = [ 3, [-2, 1, -12, 1, 6, -3, 12, 2, -2, 2, -6, -4] ];
styles["Half Mess"] = [ 4, [12, 0, 12, 0, 0, 0, -12, -3, 0, 2, -12, 2, 0, -3, -6, 0] ];
styles["Reverse Mills Mess"] = [ 3, [-12, 0, -1, 0, 12, 0, 0, 0, -12, 0, 1, 0] ];
styles["Mills 44133"] = [ 5, [-2, 0, -12, 5, 2, -2, 12, 0, 0, 2, -3, 0, 10, 0, 10, 2, 7, -2, -10, -3] ];
styles["Mills Simultaneous"] = [ 8, [6, 10, -10, 2, 6, 10, -10, -1, -4, 0, 12, 8, -4, -3, 12, 8, 6, 10, -10, -1, 6, 10, -10, 2, -4, -3, 12, 8, -4, 0, 12, 8] ];
styles["Box"] = [ 4, [10, 0, 10, 0, 10, 0, 2, 0, 10, 0, 2, 0, 10, 0, 10, 0] ];
styles["Double Box"] = [ 8, [14, 0, 7, 0, 0, 0, 0, 0, 14, 0, 0, 0, 0, 0, 14, 0, 0, 0, 0, 0, 14, 0, 7, 0, 0, 0, 14, 0, 14, 0, 0, 0] ];
styles["Advanced Box"] = [ 12, [3, 0, 10, 0, 10, 0, 3, 0, 3, 0, 10, 0, 10, 0, 3, 0, 10, 0, 3, 0, 3, 0, 10, 0, 10, 0, 3, 0, 3, 0, 10, 0, 10, 0, 3, 0, 3, 0, 10, 0, 3, 0, 10, 0, 10, 0, 3, 0] ];
styles["4-ball Box A"] = [ 3, [10, 0, 10, 0, 7, 0, 0, 0, 7, 0, 0, 0] ];
styles["4-ball Box B"] = [ 8, [10, 0, 10, 0, 10, 0, 3, 0, 10, 0, 10, 0, 10, 0, 3, 0, 10, 0, 3, 0, 10, 0, 10, 0, 10, 0, 3, 0, 10, 0, 10, 0] ];
styles["Boston Mess A"] = [ 6, [10, -2, 10, -2, 10, 3, 10, 3, 0, -2, 0, -2, -10, 3, -10, 3, -10, -2, -10, -2, 0, 3, 0, 3] ];
styles["Boston Mess B"] = [ 12, [10, 3, 10, -2, 10, 3, 10, 3, 0, -2, 0, -2, -10, 3, -10, 3, -10, -2, -10, -2, 0, 3, 0, 3, 10, -2, 10, 3, 10, -2, 10, -2, 0, 3, 0, 3, -10, -2, -10, -2, -10, 3, -10, 3, 0, -2, 0, -2] ];
styles["4-Columns (Asymmetry)"] = [ 4, [12, 0, 12, 0, 4, 0, 4, 0, 4, 0, 4, 0, 12, 0, 12, 0] ];
styles["4-Columns (Symmetry)"] = [ 4, [12, 0, 12, 0, 12, 0, 12, 0, 4, 0, 4, 0, 4, 0, 4, 0] ];
styles["4-Columns (Splits)"] = [ 4, [12, 0, 12, 0, -4, 0, -4, 0, -4, 0, -4, 0, 12, 0, 12, 0] ];
styles["5-Columns"] = [ 6, [12, 0, 12, 0, 12, 0, 12, 0, 6, 0, 6, 0, 6, 0, 6, 0, 0, 0, 0, 0, 6, 0, 6, 0] ];
styles["5-Mills Mess Columns A"] = [ 10, [-12, 0, -12, 0, -6, 0, -6, 0, 0, 0, 0, 0, 6, 0, 6, 0, 12, 0, 12, 0, 12, 0, 12, 0, 6, 0, 6, 0, 0, 0, 0, 0, -6, 0, -6, 0, -12, 0, -12, 0] ];
styles["5-Mills Mess Columns B"] = [ 10, [12, 0, 12, 0, 6, 0, 6, 0, 6, 0, 6, 0, 12, 0, 12, 0, 0, 0, 0, 0, -12, 0, -12, 0, -6, 0, -6, 0, -6, 0, -6, 0, -12, 0, -12, 0, 0, 0, 0, 0] ];
styles["5-Mills Mess Columns C"] = [ 10, [12, 3, 12, 3, -6, 0, -6, 0, 0, 3, 0, 3, 6, 0, 6, 0, -12, 3, -12, 3, -12, 0, -12, 0, 6, 3, 6, 3, 0, 0, 0, 0, -6, 3, -6, 3, 12, 0, 12, 0] ];
styles["6-Columns"] = [ 6, [15, 0, 15, 0, 15, 0, 15, 0, 9, 0, 9, 0, 9, 0, 9, 0, 3, 0, 3, 0, 3, 0, 3, 0] ];
styles["Multi-Columns"] = [ 6, [13, 0, 13, 0, 13, 0, 13, 0, 0, 0, 0, 0, 13, 0, 13, 0, 13, 0, 13, 0, 13, 0, 13, 0] ];
styles["2-ball Columns in One Hand"] = [ 4, [0, 0, 0, 0, 13, 0, 13, 0, 10, 0, 10, 0, 13, 0, 13, 0] ];
styles["3-ball Columns in One Hand"] = [ 6, [-2, 0, -2, 0, 13, 0, 13, 0, 6, 0, 6, 0, 13, 0, 13, 0, 14, 0, 14, 0, 13, 0, 13, 0] ];
styles["Cascade in One Hand"] = [ 4, [-6, 0, 2, 0, 13, 0, 13, 0, 18, 0, 10, 0, 13, 0, 13, 0] ];
styles["Martin"] = [ 3, [13, 0, 13, 0, 13, 0, 4, 0, 13, 0, 4, 0] ];
styles["9-ball Box"] = [ 15, [13, 0, 13, 0, 13, 0, 3, 0, 3, 0, 13, 0, 13, 0, 3, 0, 3, 0, 13, 0, 13, 0, 3, 0, 3, 0, 13, 0, 13, 0, 3, 0, 3, 0, 13, 0, 13, 0, 3, 0, 3, 0, 13, 0, 13, 0, 3, 0, 3, 0, 13, 0, 13, 0, 3, 0, 13, 0, 13, 0] ];
styles["20-Columns A"] = [ 20, [29, 3, 29, 3, 2, 3, 2, 3, 26, 3, 26, 3, 5, 3, 5, 3, 23, 3, 23, 3, 8, 3, 8, 3, 20, 3, 20, 3, 11, 3, 11, 3, 17, 3, 17, 3, 14, 3, 14, 3, 14, 3, 14, 3, 17, 3, 17, 3, 11, 3, 11, 3, 20, 3, 20, 3, 8, 3, 8, 3, 23, 3, 23, 3, 5, 3, 5, 3, 26, 3, 26, 3, 2, 3, 2, 3, 29, 3, 29, 3] ];
styles["20-Columns B"] = [ 20, [20, 3, 20, 3, 18, 3, 18, 3, 16, 3, 16, 3, 14, 3, 14, 3, 12, 3, 12, 3, 10, 3, 10, 3, 8, 3, 8, 3, 6, 3, 6, 3, 4, 3, 4, 3, 2, 3, 2, 3, 2, 3, 2, 3, 4, 3, 4, 3, 6, 3, 6, 3, 8, 3, 8, 3, 10, 3, 10, 3, 12, 3, 12, 3, 14, 3, 14, 3, 16, 3, 16, 3, 18, 3, 18, 3, 20, 3, 20, 3] ];
styles["Church Windows"] = [ 8, [0, 0, 0, 0, 10, 0, 10, 0, 10, 1, 10, 1, 10, -1, 10, -1, 10, 0, 10, 0, 0, 0, 0, 0, 10, -1, 10, -1, 10, 1, 10, 1] ];
styles["Hunchback"] = [ 8, [0, 0, 0, 0, 10, 0, 10, 0, 10, 1, -10, -1, 10, -1, -10, 1, 10, 0, 10, 0, 0, 0, 0, 0, 10, -1, -10, 1, 10, 1, -10, -1] ];
styles["Hunchback's Brother"] = [ 8, [0, 0, 0, 0, 10, 0, 10, 0, -10, -1, 10, 1, -10, 1, 10, -1, 10, 0, 10, 0, 0, 0, 0, 0, -10, 1, 10, -1, -10, -1, 10, 1] ];
styles["5 Ball Tennis"] = [ 7, [10, 0, 4, 0, 10, 0, 4, 0, 10, 0, 4, 0, 10, 0, 4, 0, 15, 0, 15, 0, 10, 0, 4, 0, 10, 0, 4, 0] ];
styles["1 up 4 up (standard)"] = [ 12, [10, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0, 0, 0, 0, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0, 0, 0, 0, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0] ];
styles["1 up 4 up (flashy)"] = [ 12, [15, -4, 15, 4, 15, 4, 15, -4, 15, 0, 15, 0, 0, 0, 0, 0, 15, -4, 15, 0, 15, 4, 15, 0, 15, -4, 15, 4, 15, 4, 15, -4, 0, 0, 0, 0, 15, 0, 15, 0, 15, -4, 15, 0, 15, 4, 15, 0] ];
styles["2 up 4 up (standard)"] = [ 6, [10, 0, 10, 0, 10, 0, 10, 0, 4, 0, 4, 0, 4, 0, 4, 0, 10, 0, 10, 0, 10, 0, 10, 0] ];
styles["2 up 4 up (flashy)"] = [ 6, [15, -4, 15, 4, 15, 4, 15, -4, 0, 0, 0, 0, 4, 0, 4, 0, 15, -4, 15, 0, 15, 4, 15, 0] ];
styles["Orr Multiplex"] = [ 12, [10, 0, 4, 0, 10, 0, -16, -4, 10, 0, 4, 0, 10, 0, 4, 0, 10, 0, 4, 0, 10, 0, 4, 0, 10, 0, -16, -4, 10, 0, 4, 0, 10, 0, 4, 0, 10, 0, 4, 0, 10, 0, 4, 0, 10, 0, 4, 0] ];
styles["Wide Shower"] = [ 47, [5, 0, 20, 0, 20, 0, 5, 0, 5, 0, 20, 0, 20, 0, 5, 0, 10, 0, 4, 0, 10, 0, 4, 0, 10, 0, 4, 0, 10, 0, 4, 0, 15, 0, 15, 0, 10, 0, 4, 0, 10, 0, 4, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0, 0, 0, 0, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0, 0, 0, 0, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0, 10, 0, 15, -4, 15, 4, 15, 4, 15, -4, 15, 0, 15, 0, 0, 0, 0, 0, 15, -4, 15, 0, 15, 4, 15, 0, 15, -4, 15, 4, 15, 4, 15, -4, 0, 0, 0, 0, 15, 0, 15, 0, 15, -4, 15, 0, 15, 4, 15, 0, 10, 0, 10, 0, 10, 0, 10, 0, 4, 0, 4, 0, 4, 0, 4, 0, 10, 0, 10, 0, 10, 0, 10, 0, 15, -4, 15, 4, 15, 4, 15, -4, 0, 0, 0, 0, 4, 0, 4, 0, 15, -4, 15, 0, 15, 4, 15, 0] ];
styles["JMpatt(4x2x)"] = [ 2, [0, 0, -5, 0, -5, 0, -15, 0] ];
styles["JMpatt(3333)"] = [ 4, [-10, 0, -15, 0, -15, 5, -15, 5, 20, 5, 20, 5, 10, 0, 15, 5] ];
styles["JMpatt(5141)a"] = [ 5, [15, 0, 15, 5, 10, 0, 0, 0, -20, 0, -15, 0, -15, 0, -25, 5, 20, 0, 15, 0] ];
styles["JMpatt(5141)b"] = [ 5, [0, 0, -10, 0, 15, 0, 15, 5, -10, 5, -20, 0, 15, 0, 20, 5, 0, 0, -5, 0] ];
styles["Orr Multiplex MM"] = [ 12, [10, 0, 4, 0, 10, 0, -16, -4, 10, 0, 4, 0, 10, 0, 4, 0, 10, 0, 4, 0, 10, 0, 4, 0, 10, 0, -16, -4, 10, 0, 4, 0, 10, 0, 4, 0, 10, 0, 4, 0, 10, 0, 4, 0, 10, 0, 4, 0] ];
styles["Upside-down Tennis"] = [ 3, [6, 3, 1, 3, 6, 3, 1, 3, 10, -2, -10, -2] ];
styles["Upside-down Tennis2"] = [ 6, [3, 3, 3, 3, 3, 3, 3, 3, 10, -2, -10, -2, -3, 3, -3, 3, -3, 3, -3, 3, 10, -2, -10, -2] ];
styles["Seon"] = [ 3, [13, 0, 13, 0, 0, 0, 0, 0, 0, 0, 0, 0] ];
styles["The Fastest Trick in the World"] = [ 3, [13, 0, 13, 0, 13, 1, 13, 3, 0, 0, 0, 0] ];
styles["Happy"] = [ 4, [20, 2, -5, 2, 1, 2, 20, 2, 1, 2, 20, 2, 20, 2, -5, 2] ];
styles["Exercise"] = [ 4, [20, 5, -5, 5, 20, 5, -5, 5, -5, 5, 20, 5, -5, 5, 20, 5] ];
styles["Dance"] = [ 8, [20, 5, -5, 5, -15, 5, 10, 5, 10, 5, -15, 5, -5, 5, 20, 5, -15, 5, 10, 5, 20, 5, -5, 5, -5, 5, 20, 5, 10, 5, -15, 5] ];
styles["Flags"] = [ 4, [10, 5, 10, 5, -1, 13, -1, 13, -1, 13, -1, 13, 10, 5, 10, 5] ];
styles["pwn"] = [ 6, [4, 2, 13, 0, 4, -2, 13, 0, 4, 0, 13, 0, 4, 0, 13, 0, 4, 0, 13, -2, 4, 0, 13, 2] ];
styles["pwn2"] = [ 4, [13, 0, 4, 0, 13, 0, 4, 0, 13, 0, 4, 0, 13, 8, 4, 6] ];
styles["pwn3"] = [ 4, [13, 2, 4, -2, 13, -2, 4, 2, 13, -2, 4, 2, 13, 2, 4, -2] ];

export default styles;