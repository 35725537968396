import { Link as RouterLink } from "react-router-dom";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";

const sections = [
  {
    url: "/",
    text: "Home",
  },
  {
    url: "/about",
    text: "About",
  },
  {
    url: "/tricks",
    text: "Tricks",
  },
  {
    url: "/graph",
    text: "Graph",
  },
  {
    url: "/editor",
    text: "Editor",
  },
  {
    url: "/generator",
    text: "Generator",
  },
];
function MainNavigation() {
  return (
    <ButtonGroup>
      {sections.map((s) => (
        <Button
          component={RouterLink}
          to={s.url}
          key={s.text}
          variant="body2"
        >
          {" "}
          {s.text}{" "}
        </Button>
      ))}
    </ButtonGroup>
  );
}

export default MainNavigation;
