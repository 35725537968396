import { Component, useState, MouseEvent, useEffect, useContext } from "react";
import { Pattern } from "../simulator/Pattern";
import { JugglingScene } from "./JugglingScene";
import { Slider, Stack, IconButton, Box, Container, Fab, Popover, Typography, Grid } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { ThemeContext } from "@emotion/react";
import { Theme } from "@mui/material";

interface Props {
  pattern: Pattern;
}

export default function Viewport(props: Props) {
    const theme = useContext(ThemeContext);
    const [CanvasContainerRef, setCanvasContainerRef] = useState<HTMLDivElement | null>(null);
    const [AnimationContainerRef, setAnimationContainerRef] = useState<HTMLDivElement | null>(null);
    const [jugglingScene, setJugglingScene] = useState<JugglingScene | null>(null);

    const [userControllingStep, setUserControllingStep] = useState(false);
    const [isPlaying, setIsPlaying] = useState(true);
    const [animationSpeed, setAnimationSpeed] = useState(4);
    const [fps, setFps] = useState(60);

  const updateAnimationSpeed = (speed: number) => {
    if (jugglingScene) {
      jugglingScene.animationSpeed = speed;
    }
    setAnimationSpeed(speed);
  }

  // I dont like the if (jugglinScnee) thing

  const toggle = () => {
    if (jugglingScene) {
      jugglingScene.userControllingStep = !jugglingScene.userControllingStep;
      jugglingScene.isPlaying = !jugglingScene.isPlaying;
      setIsPlaying(!isPlaying);
      setUserControllingStep(!userControllingStep);
    }
  }

  const userUpdateStep = (sliderValue: number) => {
    if (jugglingScene) {
      setIsPlaying(false);
      setUserControllingStep(!userControllingStep);
      jugglingScene.UpdateStep(sliderValue);
    }
  }


  function resize()  {
            if (CanvasContainerRef) {
                const width = CanvasContainerRef.offsetWidth;
                const height = CanvasContainerRef.offsetHeight;
                if (jugglingScene) {
                    jugglingScene!.Resize(width, height);
                }
            }
  }

    useEffect(() => {
    if (
      jugglingScene &&
      jugglingScene.pattern !== props.pattern
    ) {
      // if the pattern changed then user is no longer controlling step
      jugglingScene.userControllingStep = false;
      jugglingScene.isPlaying = true;
      setUserControllingStep(false);
      setIsPlaying(true);
      jugglingScene.UpdatePattern(props.pattern);
    }
    });


    useEffect(() => {
      if (CanvasContainerRef) {
            CanvasContainerRef.childNodes.forEach(child => child.remove());
            const width = (CanvasContainerRef as HTMLDivElement).offsetWidth;
            const height = (CanvasContainerRef as HTMLDivElement).offsetHeight;
            // var height = window.innerHeight - 32; // subtracting size of button
            setJugglingScene(new JugglingScene(
                CanvasContainerRef as HTMLDivElement,
                props.pattern,
                width,
                height,
                animationSpeed,
                fps,
                theme as Theme,
            ));
        resize();
        }
    }, [CanvasContainerRef]);


  useEffect(() => window.addEventListener("resize", resize), []);

    return (
        <Container ref={(DOMNodeRef) => {setAnimationContainerRef(DOMNodeRef)}} >
<Grid
  container
  justifyContent="center"
>
            <div style={{ height: '300px', minWidth: '300px', margin: 0 }}
                    ref={(DOMNodeRef) => { setCanvasContainerRef(DOMNodeRef) }}
            />
</Grid>
            <AnimationSettings
                isPlaying={isPlaying}
                toggle={toggle}
                userUpdateStep={userUpdateStep}
                updateAnimationSpeed={updateAnimationSpeed}
                jugglingScene={jugglingScene}
                containerRef={AnimationContainerRef}
            resize={resize}
            />
        </Container>
    )
}

function AnimationSettings({ isPlaying, toggle, userUpdateStep, updateAnimationSpeed, jugglingScene, resize, containerRef }) {
    const [open, setOpen] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const [speed, setSpeed] = useState(1);
    const [isRecording, setIsRecording] = useState(false);
    const [stop, setStop] = useState(null);

   const SpeedMap = {
     0: <SignalCellular1BarIcon />,
     1: <SignalCellular3BarIcon />,
     2: <SignalCellular4BarIcon />,
   }

  const forward = (delta: number) => {
    if (jugglingScene) {
      jugglingScene.forward(delta);
    }
  }

  const record = (event) => {

    setIsFullScreen(true); // this could be problem because async ?

    // jugglingScene.Resize(1080, 720); // to ensure good quality

    const mediaRecorder = jugglingScene.record()
    const recordedChunks = [];

    mediaRecorder.ondataavailable = function (event) {
            recordedChunks.push(event.data);
            if (mediaRecorder.state === 'recording') {
                mediaRecorder.stop();
            }
    }

    mediaRecorder.onstop = function (event) {
        const blob = new Blob(recordedChunks, {type: "video/webm"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('download', 'recordingVideo');
        link.setAttribute('href', url as string);
        alert("download finished");
        link.click();
        resize(); // not working ?
    }
    setStop(mediaRecorder.stop.bind(mediaRecorder))
    mediaRecorder.start(1000 * 60); // Limit the maximum recording length
    console.log("recording started");
    setIsRecording(true);
  }

  const stopRecord = (event) => {
    console.log("stopRecord");
    if (stop) {
      stop();
    }
    setStop(null);
    setIsRecording(false);
  };
  useEffect(() => {
    document.addEventListener('fullscreenchange', event => {
      if (!isFullScreen) {
        console.log("Exits fullscreen resize")
        // TODO
        // Change the size of the canvas/wrapper
        // resize jugglingScene
      }
    });
  }, [])

  useEffect(() => {
    if (isFullScreen && containerRef) {
      containerRef.requestFullscreen()
                  .then(() => {
                    // need to compute the best aspect
                    const size = Math.min(window.innerWidth, window.innerHeight);
                    jugglingScene.Resize(size, size);
                    // TODO: Juggler is not centered
                    jugglingScene.positionCamera();
                    // setIsFullScreen(true);
                  })
                  .catch( (err) => console.error(err) );
    } else if (!isFullScreen && document.fullscreenEnabled){
      // TODO reset the size
      // exit full screen
      document.exitFullscreen().then( () => {

      }).catch( err => console.log(err));
    }
  }, [isFullScreen])

  const adjustSpeed = (event) => {
        const newSpeed = (speed + 1) % 3;
        updateAnimationSpeed((newSpeed + 1) * 3);
        setSpeed(newSpeed);
  }

    return (
        <Stack direction="row"
               alignItems="center"
              justifyContent="center"
        >
            <IconButton
                aria-label="animation-settings"
                size="small"
                onClick={(event) => setOpen(!open)}
            >
                <SettingsIcon />
            </IconButton>
            {open &&
                <Box>
                  <IconButton onClick={adjustSpeed}
                  >
                    {SpeedMap[speed]}
                  </IconButton>

                  <IconButton onClick={(event) => forward(-16)} >
                    <FastRewindIcon />
                  </IconButton>

                  <IconButton onClick={(event) => forward(-4)} >
                    <SkipPreviousIcon />
                  </IconButton>

                  <IconButton onClick={toggle} size="small">
                    {isPlaying ? <PauseIcon />: <PlayArrowIcon />}
                  </IconButton>

                  <IconButton onClick={(event) => forward(4)} >
                    <SkipNextIcon />
                  </IconButton>

                  <IconButton onClick={(event) => forward(16)} >
                    <FastForwardIcon />
                  </IconButton>

                  <IconButton onClick={(event) => jugglingScene.positionCamera()} >
                    <CameraswitchIcon />
                  </IconButton>

                    {isRecording ? (
                     <IconButton onClick={stopRecord} >
                       <VideocamOffIcon/>
                    </IconButton>
                    ) : (
                  <IconButton onClick={record} >
                      <VideocamIcon />
                  </IconButton>)}

                  <IconButton onClick={(event) => setIsFullScreen(!isFullScreen)} >
                    { isFullScreen ? <FullscreenExitIcon />: <FullscreenIcon /> }
                  </IconButton>
                </Box>
            }
            {/* <Stack direction="row">
          {open &&
                    <Slider
                        className="viewport-slider"
                        min={0}
                        max={1}
                        step={0.005}
                        onChange={(event: Event, newValue: number | number[]) => userUpdateStep(newValue as number)}
                    />
          }
        </Stack> */}
      </Stack>
    )
}
