import axios from "axios";
import { useEffect, useState } from "react";
import { JugglingDiagram } from "./graphs/Ladder";
import JugglingTrick from "../model/JugglingTrick";
import { Box, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { BallIndicator } from "./BallIndicator";
import { DifficultyIndicator } from "./DifficultyIndicator";
import LinkIcon from '@mui/icons-material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import GunswapAnimation from "../gunswap/ui/GunswapAnimation";
import TrickWrapper from "./TrickWrapper";

type Props = {
    id: string;
}

export default function SingleTrick(props: Props) {
    const [trick, setTrick]: [JugglingTrick | null, any] = useState(null);
    useEffect(() => {
        axios
            .get(`http://localhost:4343/tricks/${props.id}`)
            .then((res) => res.data)
            .then((data) => setTrick(data))
            .catch((error) => console.log(error));
    }, [props.id, setTrick]);
    // description
    // related
    // prerequisites

    console.log(trick);
    return (
        <TrickWrapper trick={trick} content={<Wrapped trick={trick}/>}>
        </TrickWrapper>
    )
}

const Wrapped = ({trick}: {trick: JugglingTrick}) => {
    return (
        <Grid container>
            <Grid item xs={6}>
                <Stack>
                <Typography variant="h1">
                    {trick!.name}

                    <a href={trick!.url}>
                        <IconButton aria-label="Goto library of juggling">
                            <LinkIcon />
                        </IconButton>
                    </a>
                </Typography>
                <Divider />
                <Box mt={2} mb={2} >
                    <BallIndicator value={trick!.balls} />
                    <DifficultyIndicator value={trick!.difficulty} />
                    <Typography variant="subtitle1"> {trick!.siteswap} </Typography>
                </Box>
                <Divider />
                <Box mt={2} mb={2}>
                    {trick.related &&
                    <Typography variant="h6">
                    Related tricks:
                        {trick!.related.map(r => (
                                <Link key={r} to={`/tricks/${r}`}>
                                    <IconButton
                                        aria-label="Goto dedicated trick page"
                                        size="small">
                                        {r}
                                        <OpenInNewIcon />
                                    </IconButton>
                                </Link>
                        ))}
                    </Typography>}
                    {trick.prerequisites &&
                    <Typography variant="h6">
                    Prerequisites: {trick!.prerequisites.map(p => (
                                <Link key={p} to={`/tricks/${p}`}>
                                    <IconButton
                                        aria-label="Goto dedicated trick page"
                                        size="small">
                                        {p}
                                        <OpenInNewIcon />
                                    </IconButton>
                                </Link>
                        ))}
                    </Typography>}
                </Box>
                <Divider />
                <Typography mt={2} variant="body1">{trick!.description}</Typography>
                </Stack>
            </Grid>
            <Grid item>
            </Grid>
            <Grid item xs={4}>
                <GunswapAnimation site={trick!.siteswap} />
            </Grid>
            <Grid item xs={4}>
                <JugglingDiagram sequence={trick!.siteswap} />
            </Grid>

        </Grid>
    );
}
