import { ChangeEvent, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

interface SelectItem {
    value: string;
    label: string;
}

const dwellPaths: SelectItem[] = [
  {
    value: '(30)(10)',
    label: 'Cascade',
  },
  {
    value: '(10)(30)',
    label: 'Reverse Cascade',
  },
  {
    value: '(20).(20).(0)',
    label: 'Columns',
  },
  {
    value: '(20).(0,60).(0).(0,25)',
    label: 'Yo-Yo',
  },
  {
    value: '(35,15).(20)(7).(20)(7)',
    label: 'Tennis',
  },
  {
    value: '(0,10)(-20,10).(0)(20).(0,0)(-20,0)',
    label: 'Mill\'s Mess',
  },
];

export default function SelectDwellPath({onChange, value}) {

// Switch: toggle between manual and select input

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
        <TextField
          id="select-dwell-path"
          select
          label="Dwellpaths"
          value={value}
          onChange={onChange}
          helperText="Please select a dwellpath"
        >
          {dwellPaths.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
    </Box>
  );
}
