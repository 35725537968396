import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import LinkIcon from '@mui/icons-material/Link';
import Typography from "@mui/material/Typography";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from "react-router-dom";
import GunswapAnimation from "../gunswap/ui/GunswapAnimation";
import { DifficultyIndicator } from "./DifficultyIndicator";
import { BallIndicator } from "./BallIndicator";
import JugglingTrick from "../model/JugglingTrick";
import TrickWrapper from "./TrickWrapper";

type Props = {
  trick: JugglingTrick | null;
}

/*
        <IconButton aria-label="add to favorites" size="large">
          <FavoriteIcon />
        </IconButton>
        <MobileShare description={shortDescription} title={trick.name} />
 */

export default function TrickCard({ trick }: Props) {
    return (
        <Card variant="outlined">
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h4">
                                {trick.name}

                                <Link to={`/tricks/${trick.id}`}>
                                    <IconButton aria-label="Goto dedicated trick page">
                                        <OpenInNewIcon />
                                    </IconButton>
                                </Link>
                                <a href={trick.url}>
                                    <IconButton aria-label="Goto library of juggling">
                                        <LinkIcon />
                                    </IconButton>
                                </a>
                            </Typography>

                        </Grid>
                        <Grid item xs={3}>
                            <BallIndicator value={trick.balls} />
                        </Grid>
                        <Grid item xs={12}>
                            <DifficultyIndicator value={trick.difficulty} />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1"> {trick.siteswap} </Typography>
                        </Grid>
                    </Grid>
                    <Typography variant="body1">{trick.description.split(".")[0]}</Typography>
                </CardContent>
                <CardMedia>
                    <GunswapAnimation site={trick.siteswap} />
                </CardMedia>
        </Card>
    );
}
