import { useEffect, useState } from "react";
import { Siteswap } from "../simulator/Siteswap";
import { Pattern } from "../simulator/Pattern";
import { GetDwellPaths } from "../simulator/DwellPath";
import {  Grid, Stack } from "@mui/material";
import { PatternSettingsControls, PatternSettings } from "./PatternSettings";
import Viewport from "./Viewport";
import { JugglingDiagram } from "../../components/graphs/Ladder";
import { defaultPatternSettings } from "./Defaults";
import { useSearchParams } from "react-router-dom";

export default function PatternEditor(props) {
  const [searchParams, setSearchParams] = useSearchParams({
      patternSettings: JSON.stringify(defaultPatternSettings)
  });

  const getSettings = () => {
    try {
      return JSON.parse(searchParams.get('patternSettings'));
    } catch (err) {
      console.error(err);
    }
  }
  const settings = searchParams.has('patternSettings')
                 ? getSettings()
                 : defaultPatternSettings;

  const setSettings = (newValue) => {
    const patternSettingsQueryString = JSON.stringify(newValue);
    setSearchParams({
          patternSettings: patternSettingsQueryString,
      })
  }
  console.log(settings);

  const [pattern, setPattern] = useState<Pattern>(
      new Pattern(
        new Siteswap(settings.siteswap),
        GetDwellPaths(settings.dwellPath),
        settings.dwellRatio,
        0
      ));

    useEffect(() => {
        pattern.Simulate(100, 0.24);
    }, [pattern]);

  const updatePattern = (pattern: Pattern, patternSettings: PatternSettings) => {
      setPattern(pattern);
      const patternSettingsQueryString = JSON.stringify(patternSettings);
      console.log(patternSettingsQueryString, patternSettings)
      setSearchParams({
          patternSettings: patternSettingsQueryString,
      })
  };

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Stack>
                    <PatternSettingsControls
                        updatePattern={updatePattern}
                        PREsettings={settings}
                    ></PatternSettingsControls>
                </Stack>
            </Grid>
            <Grid item xs={8}>
                <Viewport pattern={pattern} />
                <JugglingDiagram sequence={settings.siteswap} />
            </Grid>
        </Grid>
    );

}
