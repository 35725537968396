import { CircularProgress } from "@mui/material";
import { ReactChild } from "react";
import JugglingTrick from "../model/JugglingTrick";

interface Props {
    trick: JugglingTrick | null;
    content?: ReactChild | ReactChild[];
    children: ReactChild | ReactChild[];
}

export default function TrickWrapper(props: Props) {
    console.log(props.trick);
    if (props.trick) {
    console.log("yeet");
        return <div>{props.content}</div>
    } else {
        return <CircularProgress />
    }
}
