import axios, { AxiosResponse } from "axios";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Pagination from '@mui/material/Pagination';
import SearchIcon from '@mui/icons-material/Search';

import TrickCard from "../components/TrickCard";
import useQueryString from "../hooks/useQueryString";
import JugglingTrick from "../model/JugglingTrick";
import { Box, Button, Divider, Slider, TextField, Typography } from "@mui/material";

const minDifficulty = 1;
const maxDifficulty = 10;
const defaultDifficultyRange = [minDifficulty, maxDifficulty];

const minBalls = 1;
const maxBalls = 7;
const defaultBallRange = [minBalls, maxBalls];

const defaultTricksPerPage = 12;

const defaultSort = "&_sort=balls,name";

export default function Tricks(props) {
  const [tricks, setTricks] = useState([]);
  const [tricksPerPage, setTricksPerPage] = useState<number>(defaultTricksPerPage);
  const [numberTricks, setNumberTricks] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [queryParam, onSetQuery] = useQueryString("q", "");

  const [difficultyRange, setDifficultyRange] = useState<number[]>(defaultDifficultyRange);
  const [ballRange, setBallRange] = useState<number[]>(defaultBallRange);

  useEffect(() => {
    fetchTricks();
  }, [page, queryParam]);

  const handleSearchUpdate = () => {
    setPage(1);
    fetchTricks();
  }

  const fetchTricks = () => {
    const start = (page - 1) * tricksPerPage;
    const end = page * tricksPerPage
    let url = `http://localhost:4343/tricks/?_start=${start}&_end=${end}`;
    if (queryParam) {
      url += `&q=${queryParam}`;
    }
    if (difficultyRange) {
      url += `&difficulty_gte=${difficultyRange[0]}&difficulty_lte=${difficultyRange[1]}`;
    }
    if (ballRange) {
      url += `&balls_gte=${ballRange[0]}&balls_lte=${ballRange[1]}`;
    }
    url += defaultSort;
    axios
      .get(url)
      .then((res: AxiosResponse) => {
        const xHeader = 'x-total-count';
        if (xHeader in res.headers) {
          setNumberTricks(parseInt(res.headers[xHeader]));
        }
        setTricks(res.data);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSetQuery(event.target.value);
    }
  };

    return (
        <Container>
          <Grid sx={{m: 2}} container spacing={2} alignItems="center" >
              <Grid item>
                <TextField label="Search" onKeyDown={handleKeyDown} />
              </Grid>
              <Grid item>
                <Typography id="difficulty-slider" gutterBottom>
                    Difficulty range filter
                </Typography>
                <Slider
                    aria-labelledby="difficulty-slider"
                    marks
                    step={1}
                    min={minDifficulty}
                    max={maxDifficulty}
                    value={difficultyRange}
                    valueLabelDisplay="auto"
                    onChange={(event, value: number | number[]) => setDifficultyRange(value as number[])}
                    disableSwap
                />

                <Typography id="balls-slider" gutterBottom>
                  Number of balls
                </Typography>
                <Slider
                    aria-labelledby="balls-slider"
                    marks
                    color="secondary"
                    step={1}
                    min={minBalls}
                    max={maxBalls}
                    value={ballRange}
                    valueLabelDisplay="auto"
                    onChange={(event, value: number | number[]) => setBallRange(value as number[])}
                    disableSwap
                />
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={()=>handleSearchUpdate()}>
                  <SearchIcon /> Update Search
                </Button>
              </Grid>
                <Grid item>
                {numberTricks > tricksPerPage &&
                 <>
                    <Typography id="pagination" gutterBottom>
                      Check out the tricks on the other pages
                    </Typography>
                    <Pagination
                        count={Math.floor(numberTricks / tricksPerPage)}
                        page={page}
                        variant="outlined"
                        color="secondary"
                        aria-labelledby="pagination"
                        onChange={(event, value) => setPage(value)}
                    />
                </>
                }
                </Grid>
            </Grid>
            <Divider variant="middle" />
            <Box sx={{m: 2}}>
              <TrickCards tricks={tricks} />
            </Box>
        </Container>
    );
}


const TrickCards = ({ tricks }: { tricks: JugglingTrick[] }) => {
    return (
        <Grid container spacing={3}>
            {tricks.map((trick, i) => {
                return (
                  <Grid key={i} item xs={4}>
                        <TrickCard trick={trick} />
                    </Grid>
                );
            })}
        </Grid>
    );
};
