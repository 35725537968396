import { createTheme } from '@mui/material/styles'

export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#ffb703',
    },
    secondary: {
      main: '#023047',
    },
    error: {
      main: '#ae2012',
    },
    warning: {
      main: '#fb8500',
    },
  },
  typography: {
    fontFamily: 'Open Sans',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
};

export const lightTheme = createTheme(themeOptions);
const darkOptions = {...themeOptions};
darkOptions.palette.mode = 'dark';
export const darkTheme = createTheme(darkOptions);
