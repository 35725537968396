import {
  FormControl,
  FormHelperText,
  Checkbox,
  Box,
  FormControlLabel,
  InputLabel,
  TextField,
  Table,
  TableRow,
  TableCell,
  styled,
} from "@mui/material";
import { useState, useEffect } from "react";
import { purple, green, blue, red, yellow, orange, cyan, pink } from '@mui/material/colors';
import {lightTheme} from '../ui/theme'
import GunswapAnimation from "../gunswap/ui/GunswapAnimation";

/**
 * Problems:
   - higher number than 9 are interpreted wrong as a siteswap
   Need a fucntion array -> siteswap string that parses correctly
   If gunswap even supports letters?
 */

export function PickAPattern() {
  const [period, setPeriod] = useState("5");
  const [p, setP] = useState(5);
  const [b, setB] = useState(3);
  const [balls, setBalls] = useState("3");
  const [transform, setTransform] = useState(false);
  const [site, setSite] = useState("441");

  const [data, setData] = useState(
    Array(p)
      .fill([])
      .map((_, i) => Array(p).fill(i))
  );
  const [selected, setSelected] = useState(
    Array(p)
      .fill([])
      .map((_, i) => Array(p).fill(0))
  );
  // Careful: permutation test also updates siteswap!
  useEffect(() => {
    setP(parseInt(period));
    setB(parseInt(balls));
    setSelected([...Array(p)].map((_, j) => [...Array(p)].map((_, i) => 0)));
  }, [period, balls]);

  useEffect(() => {
    setData(
      [...Array(p)].map((_, j) =>
        [...Array(p)].map((_, i) => {
          if (transform) {
            return permutationMapping(i, j, b, p);
          } else {
            return pickPatternMapping(i, j, b);
          }
        })
      )
    );
  }, [transform, balls, period]);

  return (
    <Box>
      <FormControl>
        <TextField
          id="ball-number"
          label="Balls"
          type="number"
          variant="filled"
          value={balls}
          onChange={(event) => setBalls(event.target.value)}
          aria-describedby="ball-helper-text"
        />
        <FormHelperText id="ball-helper-text">
          Number of balls in the pattern
        </FormHelperText>

        <TextField
          id="period-number"
          label="Period"
          type="number"
          variant="filled"
          value={period}
          onChange={(event) => setPeriod(event.target.value)}
          aria-describedby="period-helper-text"
        />
        <FormHelperText id="period-helper-text">
          The period of the pattern
        </FormHelperText>

        <FormControlLabel
          control={
            <Checkbox
              checked={transform}
              onChange={(e) => setTransform(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Permutation test"
        />
      </FormControl>
      <Matrix
        data={data}
        setSelected={setSelected}
        selected={selected}
        period={parseInt(period)}
        balls={parseInt(balls)}
        mapping={transform}
        setSite={transform ? () => {} : setSite}
      />
      <div>Site: {site} </div>
      <GunswapAnimation site={site} />
    </Box>
  );
}

interface MatrixProps {
  period: number;
  balls: number;
  mapping: boolean;
  setSite: any;
  data: any;
  selected: any;
  setSelected: any;
}

function pickPatternMapping(i: number, j: number, balls: number): number {
  return balls - i + j;
}

function permutationMapping(
  i: number,
  j: number,
  balls: number,
  period: number
): number {
  return (balls + j - 1) % period;
}

function Matrix({
  period,
  balls,
  mapping,
  setSite,
  data,
  selected,
  setSelected,
}: MatrixProps) {
  const handleClick = (i: number, j: number) => {
    const sel = selected.slice();
    if (selected[j][i] === 0) {
      for (let y = 0; y < period; y++) {
        sel[y][i] = 2;
      }
      for (let x = 0; x < period; x++) {
        sel[j][x] = 2;
      }
      sel[j][i] = 1;
    } else if (selected[j][i] === 1) {
      for (let y = 0; y < period; y++) {
        sel[y][i] = 0;
      }
      for (let x = 0; x < period; x++) {
        sel[j][x] = 0;
      }
      sel[j][i] = 0;
    } else {
      // Maybe show error
    }
    setSelected(sel);
  };

  return (
    <table
            style={{
                borderSpacing: 0,
            }}
        >
            <tbody>
                {data.map((row, j) => (
                  <tr key={j}>
                        {row.map((_, i) => (
                            <MatrixElement
                                key={i}
                                selected={selected[j][i]}
                                handleClick={() => handleClick(i, j)}
                                value={data[j][i]}
                            />))}
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <SelectedRow selected={selected} data={data} setSite={setSite} />
            </tfoot>
        </table>
    );
}
function SelectedRow({ selected, data, setSite }) {
    const size = selected.length;
    const site = [];

    const selection = Array(size).fill(null);
    for (let i = 0; i < size; i++) {
        for (let j = 0; j < size; j++) {
            if (selected[j][i] === 1) {
                selection[i] = data[j][i];
                site.push(data[j][i]);
            }
        }
    }
    if (selection.every((s) => s !== null)) {
        setSite(site.join(""));
    }

    const row = Array(size)
        .fill("")
        .map((_, i) => (
            <td
                key={i}
                style={{
                    borderWidth: 1,
                    width: "2rem",
                    height: "2rem",
                    borderStyle: "solid",
                    borderColor: lightTheme.palette.text.primary,
                    textAlign: "center",
                    color: lightTheme.palette.text.secondary,
                    backgroundColor: selection[i] !== null ? lightTheme.palette.primary.main : lightTheme.palette.background.paper,
                }}
            >
                {" "}
                {selection[i]}{" "}
            </td>
        ));
    return <tr>{row}</tr>;
}

function getColor(i: number): string {
    if (i === 0) return lightTheme.palette.background.paper;
    else if (i === 1) return lightTheme.palette.primary.main;
    else return lightTheme.palette.grey[300];
}

function MatrixElement({
    value,
    handleClick,
    selected,
}: {
    value: any;
    handleClick: any;
    selected: number;
}) {
    return (
        <td
            style={{
                borderWidth: 1,
                width: "2rem",
                height: "2rem",
                borderStyle: "solid",
                textAlign: "center",
                color: selected !== 0 ? "white" : "black",
                backgroundColor: getColor(selected),
            }}
            onClick={handleClick}
        >
            {" "}
            {value}{" "}
        </td>
    );
}

export function PickAPatternConcat() {
    const [period1, setPeriod1] = useState("2");
    const [period2, setPeriod2] = useState("3");
    const [balls, setBalls] = useState("3");
    const [transform, setTransform] = useState(false);
    const [site, setSite] = useState("441");
    // Need to move the data out!
    return (
        <Box>
            <FormControl>
                <TextField
                    id="ball-number"
                    label="Balls"
                    type="number"
                    variant="filled"
                    value={balls}
                    onChange={(event) => setBalls(event.target.value)}
                    aria-describedby="ball-helper-text"
                />
                <FormHelperText id="ball-helper-text">
                    Number of balls in the pattern
                </FormHelperText>

                <TextField
                    id="period-numbe1r"
                    label="Period1"
                    type="number"
                    variant="filled"
                    value={period1}
                    onChange={(event) => setPeriod1(event.target.value)}
                    aria-describedby="period-helper-text1"
                />
                <FormHelperText id="period-helper-text1">
                    The period of the pattern
                </FormHelperText>

                <TextField
                    id="period-number2"
                    label="Period2"
                    type="number"
                    variant="filled"
                    value={period2}
                    onChange={(event) => setPeriod2(event.target.value)}
                    aria-describedby="period-helper-tex2t"
                />
                <FormHelperText id="period-helper-text2">
                    The period of the pattern
                </FormHelperText>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={transform}
                            onChange={(e) => setTransform(e.target.checked)}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    }
                    label="Permutation test"
                />
            </FormControl>
            {/* <Matrix period={parseInt(period1)} balls={parseInt(balls)} mapping={transform} setSite={()=>{}}/>
            <Matrix period={parseInt(period2)} balls={parseInt(balls)} mapping={transform} setSite={()=>{}}/>

            <Matrix period={parseInt(period1) + parseInt(period2)} balls={parseInt(balls)} mapping={transform} setSite={setSite}/> */}
            <div>Site: {site} </div>
            <GunswapAnimation site={site} />
        </Box>
    );
}
