import Juggler from "./Juggler";

import JMValidator from '../../lib/validator';
import {useState} from 'react';

export default function Wrapper() {
    // Validate the siteswap
    // if (! JMValidator.validateSite(site)) site = '0';

    return (
            <Juggler />
    )
}
