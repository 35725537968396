import React, {
  Component,
  createRef,
  useRef,
  useState,
  useEffect,
} from "react";
import * as d3 from "d3";
import { Siteswap } from "../../gunswap/simulator/Siteswap";
import { GetDwellPaths } from "../../gunswap/simulator/DwellPath";

// grp -> color
// n -> size
// name -> label
// id -> internal and links
interface Node {
  id: number;
  name: number;
  juggler: number;
  grp: number;
  n: number;
}

interface Link {
  source: number;
  target: number;
  value: string;
  color: number;
}

interface Dataset {
  nodes: Node[];
  links: Link[];
}

const D3Graph = ({ dataset, numJuggler, beats }: {dataset: Dataset, numJuggler: number, beats:number}) => {
  const ref = useRef();
  const margin = { top: 0, right: 30, bottom: 50, left: 60 },
    width = 650 - margin.left - margin.right,
    height = 400 - margin.top - margin.bottom;


  useEffect(() => {
    const svg = d3.select(ref.current);
    svg
      .html("")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);
    svg
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    // TODO abstract
    const juggler0 = dataset.nodes.filter(d => d.juggler == 0);
    const juggler1 = dataset.nodes.filter(d => d.juggler == 1);
    const juggler2 = dataset.nodes.filter(d => d.juggler == 2);

    const allNodes = [...dataset.nodes.map(d => d.id)];
    const allGroups = new Array(new Set(dataset.nodes.map(d => d.grp)).values());
    // A color scale for groups:
    let color = d3.scaleOrdinal().domain(allGroups).range(d3.schemeSet3);
    // A linear scale for node size
    let size = d3.scaleLinear().domain([1, 10]).range([2, 10]);
    // A linear scale to position the nodes on the X axis
    let x = d3.scalePoint().range([10, width-10]).domain(allNodes);

    function nodeLine(nodes, heightOffset) {
      return svg
        .selectAll("mynodes")
        .data(nodes)
        .enter()
        .append("circle")
        .attr("cx", d => x(d.id))
        .attr("cy", height - heightOffset)
        .attr("r", d => size(d.n))
        .style("fill", d => color(d.grp))
        .attr("stroke", d => color(d.grp))
    };
    const line0 = nodeLine(juggler0, 30);
    const line1 = nodeLine(juggler1, 150);
    const line2 = nodeLine(juggler2, 270);

    const nodes = line0;
    // const nodes: d3.no = [...line0, ...line1, ...line2];

    const JugglerDiff = 120;

    function arcs(links, heightOffset) {
      return svg
      .selectAll("mylinks")
      .data(links)
      .enter()
      .append("path")
      .attr("d", function (d: Link) {
        const start = x(d.source); // X position of start node on the X axis
        const end = x(d.target); // X position of end node
        // TODO: support "cross level" throws
        const sourceJuggler = Math.floor(d.source / beats);
        const targetJuggler = Math.floor(d.target / beats);
        // TODO make them direct if cross-level
        const inflexion = sourceJuggler === targetJuggler ? (start - end) / 2 : 0;
        return [
          "M",
          start,
          height - heightOffset - JugglerDiff * sourceJuggler, // the arc starts at the coordinate x=start, y=height-30 (where the starting node is)
          "A", // This means we're gonna build an elliptical arc
          inflexion,
          ",", // Next 2 lines are the coordinates of the inflexion point. Height of this point is proportional with start - end distance
          inflexion,
          0,
          0,
          ",",
          start < end ? 1 : 0, // I guess we could throw backwards -> maybe use for anti balls/throws ?!
          end,
          ",",
          height - heightOffset - JugglerDiff * targetJuggler
        ] // We always want the arc on top. So if end is before start, putting 0 here turn the arc upside down.
          .join(" ");
      })
      .style("fill", "none")
      .attr("stroke", "grey")
      .style("stroke-width", 2);
    }

    const links = arcs(dataset.links, 30);

    function label(nodes, heightOffset) {
      return svg
      .selectAll("mylabels")
      .data(dataset.nodes)
      .enter()
      .append("text")
      .attr("x", 0)
      .attr("y", 0)
      .text(d => d.name)
      .style("text-anchor", "end")
      .style("font-size", 5)
      .attr("transform", d => "translate(" + (x(d.id) + 5)  + "," + (height - heightOffset) + ")");

    }
    // And give them a label
    const label0 = label(juggler0, 10);
    const label1 = label(juggler1, 130);

    // Add the highlighting functionality

      function hoverNodes(nodes) {
          nodes
              .on("mouseover", function (d) {
                  // I probably shouldnt be doing this :)
                  d = d.srcElement.__data__;
                  // Highlight the nodes: every node is green except of him
                  nodes.style("opacity", 0.2);
                  d3.select(this).style("opacity", 1);
                  // Highlight the connections
                  links
                      .style("stroke", function (link_d) {
                          console.log(link_d.source, d.id, link_d.target, d.grp, d);
                          return link_d.source === d.id || link_d.target === d.id
                              ? color(d.grp)
                              : "#b8b8b8";
                      })
                      .style("stroke-opacity", function (link_d) {
                          return link_d.source === d.id || link_d.target === d.id ? 1 : 0.2;
                      })
                      .style("stroke-width", function (link_d) {
                          return link_d.source === d.id || link_d.target === d.id ? 4 : 1;
                      });
                  label0
                      .style("font-size", function (label_d) {
                          return label_d.name === d.name ? 16 : 2;
                      })
                      .attr("y", function (label_d) {
                          return label_d.name === d.name ? 10 : 0;
                      });
              })
              .on("mouseout", function (d) {
                  nodes.style("opacity", 1);
                  links
                      .style("stroke", "grey")
                      .style("stroke-opacity", 0.8)
                      .style("stroke-width", 2);
                  label0.style("font-size", 6);
              });
      }
    hoverNodes(line0);
    hoverNodes(line1);
    hoverNodes(line2);

      // TODO: abstract config values, common reset function

    function hoverLinks(links) {
      links
          .on("mouseover", function (d) {
              d = d.srcElement.__data__;
              links.style("stroke-opacity", 0.2);
              d3.select(this).style("opacity", 1);
              links
                  .style("stroke", function (link_d) {
                      return link_d.color === d.color
                          ? color(d.color)
                          : "grey";
                  })
                  .style("stroke-opacity", function (link_d) {
                      return link_d.color === d.color
                          ? 1 : 0.2
                  })
                  .style("stroke-width", function (link_d) {
                      return link_d.color === d.color
                          ? 4 : 1;
                  });
              nodes
                  .style("opacity", (node_n) => node_n.grp === d.color ? 1 : 0.2);
          })
          .on("mouseout", function (d) {
              links
                  .style("stroke", "grey")
                  .style("opacity", 1)
                  .style("stroke-opacity", 0.8)
                  .style("stroke-width", 2);
              nodes.style("opacity", 1);
          });
    }
    hoverLinks(links);


  }, [dataset, height, width]);

    return (
        <svg
            width={width + margin.left + margin.right}
            height={height + margin.top + margin.bottom}
            viewBox={"0 0 " + width + " " + height}
            ref={ref}
        />
    )
}

export const TestD3 = () => {
    return (
        <div>
            <h1>Testing D3</h1>
            <JugglingDiagram sequence="531" />
        </div>
    );
};

export function JugglingDiagram({ sequence }) {
    const nums = sequence.split("").map(s => parseInt(s));

    // BEATS
    const dwellPaths = GetDwellPaths("(30)");
    const defaultDwellRatio = 1;
    const numSurfaces = 1;
    const site = new Siteswap(sequence);

    /*   console.log(site); */
    /*   console.log(dwellPaths); */
    const tossCollection = site.GetTossCollection(dwellPaths, defaultDwellRatio, numSurfaces)

    // TODO link colors are kinda broken
    // TODO dynamic length
    // TODO viewport

    const data: Dataset = {
        nodes: [],
        links: [],
    }

    const numNodes = 20;
    const color = Array(nums).fill(0);
    nums.forEach((_, i) => color[i] = i);

    /* tossCollection.forEach((tosses, beat) => {
*     tosses.forEach((toss, i) => {
*         const link: Link = {
*             source: beat + numNodes * toss.Juggler,
*           target: (beat + toss.NumBeats) + numNodes * toss.TargetJuggler,
*             value: toss.Siteswap,
*             color: color[beat], // TODO
*         }
*         data.links.push(link);
*     })
*   }) */
    Array(numNodes).fill(0).forEach((_, beat) => {
        tossCollection[beat % tossCollection.length].forEach(toss => {
            // TODO: multiple hands
            const target = beat + toss.NumBeats;
            color[target] = color[beat];
            if (target < numNodes) {
                data.links.push({
                    source: beat + numNodes * toss.Juggler,
                    target: target + numNodes * toss.TargetJuggler,
                    value: toss.Siteswap,
                    color: color[beat],
                })
            }
        });

        for (let nj = 0; nj < site.NumJugglers; nj++) {
            data.nodes.push({
                id: numNodes * nj + beat,
                juggler: nj,
                name: (beat + 1) % nums.length + 1,
                grp: color[beat],
                n: 5,
            })
        }
    });
    // nums.forEach((n, i)=> {
    //   data.links.push({
    //     source: i,
    //     target: i + n
    //   })
    // })
    return (
        <D3Graph dataset={data} numJuggler={site.NumJugglers} beats={numNodes}/>
    )
}

/*
const useInterval = (callback, delay) => {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const generateDataset = () =>
  Array(10)
    .fill(0)
    .map(() => [Math.random() * 80 + 10, Math.random() * 35 + 10]);

const Circles = () => {
  const [dataset, setDataset] = useState(generateDataset());
  const ref = useRef();
  useEffect(() => {
    const svgElement = d3.select(ref.current);
    svgElement
      .selectAll("circle")
      .data(dataset)
      .join("circle")
      .attr("cx", (d) => d[0])
      .attr("cy", (d) => d[1])
      .attr("r", 3);
  }, [dataset]);
  useInterval(() => {
    const newDataset = generateDataset();
    setDataset(newDataset);
  }, 2000);

  const handleSubmit = (event) => {
    event.preventDefault();
    const val = event.target[0].value;
    setDataset(JSON.parse(val));
  };
  return (
    <div>
      <h1>Testing D3</h1>
      <NameForm handleSubmit={handleSubmit} data={dataset}></NameForm>
      <svg viewBox="0 0 100 50" ref={ref} />
    </div>
  );
};

*/
