import { useState } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ThemeProvider } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness3Icon from "@mui/icons-material/Brightness3";

import { lightTheme, darkTheme } from './theme';
import MainNavigation from "./MainNavigation";

export default function Layout(props) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState(prefersDarkMode);
  const icon = mode ? <Brightness7Icon /> : <Brightness3Icon />;

  // Wrap the application in theme provider (uses react context to pass down)
  return (
    <ThemeProvider theme={mode ? darkTheme : lightTheme}>
        <CssBaseline />
        <AppBar position="sticky">
          <Toolbar>
            <img  width="40" height="40" src='/logo.png' alt='logo'/>
            <Typography variant="h6"> uggle! </Typography>
            <MainNavigation />
            <div style={{ flexGrow: 1 }} />
            <IconButton onClick={() => setMode(!mode)} size="large">{icon}</IconButton>
          </Toolbar>
        </AppBar>
        <br/>
        <Container>
          {props.children}
        </Container>
      </ThemeProvider>
  )
}
