import { useEffect } from 'react';

function draw(ctx, jmlib, getColor) {
    if (!ctx) return;
    if (!jmlib.arm) return;
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.lineWidth = '1.0';
    // this.props.theme.palette.text.main;
    ctx.strokeStyle = "black";

    // draw head
    ctx.beginPath();
    ctx.moveTo(jmlib.arm.hx+jmlib.arm.hr, jmlib.arm.hy);
    ctx.arc(jmlib.arm.hx, jmlib.arm.hy, jmlib.arm.hr, 0, Math.PI*2, false);
    ctx.closePath();
    ctx.stroke();

    // draw arms
    for (let i=0;i<5;i++) {
        ctx.beginPath();
        ctx.moveTo(jmlib.arm.rx[i], jmlib.arm.ry[i]);
        ctx.lineTo(jmlib.arm.rx[i+1], jmlib.arm.ry[i+1]);
        ctx.closePath();
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(jmlib.arm.lx[i], jmlib.arm.ly[i]);
        ctx.lineTo(jmlib.arm.lx[i+1], jmlib.arm.ly[i+1]);
        ctx.closePath();
        ctx.stroke();
    }

    // draw hands
    for (let i=0; i < 10; i++) {
        ctx.beginPath();
        ctx.moveTo(jmlib.handBall1.gx + jmlib.hand1.rx[i],        jmlib.handBall1.gy + jmlib.hand1.ry[i]);
        ctx.lineTo(jmlib.handBall1.gx + jmlib.hand1.rx[(i+1)%10], jmlib.handBall1.gy + jmlib.hand1.ry[(i+1)%10]);
        ctx.closePath();
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(jmlib.handBall2.gx + jmlib.hand1.lx[i],        jmlib.handBall2.gy + jmlib.hand1.ly[i]);
        ctx.lineTo(jmlib.handBall2.gx + jmlib.hand1.lx[(i+1)%10], jmlib.handBall2.gy + jmlib.hand1.ly[(i+1)%10]);
        ctx.closePath();
        ctx.stroke();
    }

    // draw balls
    let r = jmlib.ballRadius;
    if (r < 1) { r = 1; }
    for (let i = 0; i < jmlib.ballNumber; i++) {
        let ball = jmlib.balls[i];
        ctx.beginPath();
        ctx.moveTo(ball.gx+r+r, ball.gy);
        ctx.arc(ball.gx+r, ball.gy+r, r, 0, Math.PI*2, false);
        ctx.fillStyle = getColor(i);
        ctx.fill();
        ctx.closePath();
    }
}

export default function JuggleMaster(props) {
    useEffect( () => {
        draw(props.ctx, props.animation, props.getColor);
    }, [props.animation, props.getColor]);
    return null;
}