
import {patternIdx } from '../../lib/patterns';
import styles from '../../lib/styles';
import JML from './JML';
import JuggleMaster from './JuggleMaster';

import {useEffect, useState, useRef} from 'react';

const Juggler = (props) => {
	// Get url parameters https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
	const params = new URLSearchParams(window.location.search);

    const [site, setSite] = useState(params.get('site'));
    const [style, setStyle] = useState(params.get('style'));
    const [pattern, setPattern] = useState(params.get('pattern'));
    const [pattNo, setPattNo] = useState(params.get('pattno'));

    const [animation, setAnimation] = useState({});
    const [ctx, setCtx] = useState(null);
    const [status, setStatus] = useState(true);

    const canvasRef = useRef(null);

    const intervalMs = 10;


    useEffect(() => {
        const canvas = canvasRef.current;
        setCtx(canvas.getContext('2d'));
    }, [])


    const colors = [
            'red',
            'blue',
            'green',
            'orange',
            'pink',
            'purple',
            'magenta',
            'red',
        ];

    const getColor = (i) => {
        return colors[i % colors.length]
    }

    return (
        <div>
            <label>
                Startjuggle
                <input type="button" onClick={() => {setStatus(true)}} />
            </label>
            <label>
                StopJuggle
                <input type="button" onClick={() => {setStatus(false)}} />
            </label>
            <label>
                togglePause
                <input type="button" onClick={() => {setStatus(! status)}} />
            </label>
            <label>
                Load pattern
                <SelectPattern value={pattern} onChange={event => setPattern(event.target.value)} />
            </label>
            <label>
                Select style
                <SelectStyle value={style} onChange={event => setStyle(event.target.value)} />
            </label>
            <label>
                siteswap
                <input type="text" value={site} onChange={event => {
                    setSite(event.target.value)
                    setPattern(null);
                    setPattNo(null);
                }} /> 
            </label>
            <JML site={site} style={style} pattern={pattern} pattNo={pattNo} ctx={ctx} setAnimation={setAnimation} />
            <JuggleMaster getColor={getColor} animation={animation} ctx={ctx} />
            <canvas width="500px" height="500px" ref={canvasRef}></canvas>
        </div>
    )
}

 const SelectPattern = (props) => {
     return (
         <select value={props.value} onChange={(e) => props.onChange(e)}>
             {patternIdx.map( name => {
                 return (
                 <option value={name} >
                     {name}
                 </option>
                 )
             })}
         </select>
     )
 }

 const SelectStyle = (props) => {
     return (
         <select value={props.value} onChange={(e) => props.onChange(e)}>
             {Array.from(styles.keys()).map( name => {
                 return (
                 <option value={name} >
                     {name}
                 </option>
                 )
             })}
         </select>
     )
 }

export default Juggler;