import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Stack } from "@mui/material";

export function DifficultyIndicator(props) {
    let container = [];
    const maxDifficulty = 10;
    for (let i = 0; i < props.value; i++) {
        container.push(<RadioButtonUncheckedIcon key={i} color="primary" />);
    }
    for (let i = props.value; i < maxDifficulty; i++) {
        container.push(<RadioButtonUncheckedIcon key={i} color="disabled" />);
    }
    return <Stack direction="row">{container}</Stack>;
}
