// BEGIN Pattern and Group data
let patternArray = {};
let groups = [];
patternArray[0] = [ "3-Cascade", "3", "Normal", 0.20, 0.50, "" ];
patternArray[1] = [ "Slower 3-Cascade", "3", "Normal", 0.20, 0.75, "" ];
patternArray[2] = [ "1 Ball out of a 3-Cascade", "300", "Normal", 0.20, 0.50, "" ];
patternArray[3] = [ "Throw Twice", "33022", "Normal", 0.20, 0.50, "" ];
patternArray[4] = [ "2 Balls out of a 3-Cascade", "330", "Normal", 0.20, 0.50, "" ];
patternArray[5] = [ "Throw 3 Times", "[32]3322", "Normal", 0.20, 0.50, "" ];
groups[0] = [ "3-Cascade Step By Step ", 0, 5 ];
patternArray[6] = [ "2 in One Hand", "40", "Normal", 0.20, 0.50, "" ];
patternArray[7] = [ "4-Synchronous Fountain", "(4,4)", "Normal", 0.20, 0.50, "" ];
patternArray[8] = [ "4-Fountain", "4", "Normal", 0.20, 0.50, "" ];
groups[1] = [ "4-Fountain Step By Step ", 6, 8 ];
patternArray[9] = [ "2 Balls out of a 5-Cascade", "50500", "Normal", 0.20, 0.50, "" ];
patternArray[10] = [ "3 Balls", "52512", "Normal", 0.20, 0.50, "" ];
patternArray[11] = [ "Chase", "50505", "Normal", 0.20, 0.50, "" ];
patternArray[12] = [ "Flash", "55500", "Normal", 0.20, 0.50, "" ];
patternArray[13] = [ "4 Balls", "[52][52]55022[22][22]", "Normal", 0.20, 0.50, "" ];
patternArray[14] = [ "4 Balls out of a 5-Cascade", "55550", "Normal", 0.20, 0.50, "" ];
patternArray[15] = [ "552", "552", "Normal", 0.20, 0.50, "" ];
patternArray[16] = [ "5551", "5551", "Normal", 0.20, 0.50, "" ];
patternArray[17] = [ "5-Cascade", "5", "Normal", 0.20, 0.50, "" ];
groups[2] = [ "5-Cascade Step By Step ", 9, 17 ];
patternArray[18] = [ "3-ball Flash", "333355500", "Normal", 0.30, 0.50, "" ];
patternArray[19] = [ "Chop", "(2,4x)(4x,2)", "Chop", 0.12, 0.50, "" ];
patternArray[20] = [ "Eating Apples", "(2,4x)(4x,2)", "Eating Apples", 0.12, 0.50, "" ];
patternArray[21] = [ "Eating an Apple (for light eaters)", "33333423", "Eating an Apple", 0.20, 0.50, "" ];
patternArray[22] = [ "Wide Cascade", "3", "Wide", 0.12, 0.50, "" ];
patternArray[23] = [ "Juggler's Tennis", "3", "Tennis", 0.20, 0.50, "" ];
patternArray[24] = [ "Over the Head", "3", "Over the Head", 0.20, 0.50, "" ];
patternArray[25] = [ "Reverse Cascade", "3", "Reverse", 0.20, 0.50, "" ];
patternArray[26] = [ "Wide Reverse Cascade", "3", "Wide Reverse", 0.20, 0.50, "" ];
patternArray[27] = [ "Reachover", "3", "Reachover", 0.15, 0.50, "" ];
patternArray[28] = [ "Reachover (alternate)", "3", "Reachover (alternate)", 0.15, 0.50, "" ];
patternArray[29] = [ "Reachunder", "3", "Reachunder", 0.15, 0.50, "" ];
patternArray[30] = [ "Reachunder (alternate)", "3", "Reachunder (alternate)", 0.15, 0.50, "" ];
patternArray[31] = [ "Crossed Arm Reverse", "3", "Crossed Arm Reverse", 0.20, 0.50, "" ];
groups[3] = [ "3-Cascade Tricks ", 18, 31 ];
patternArray[32] = [ "1Up-2Up A (Columns)", "(0,4)(4,4)", "1Up-2Up A", 0.14, 0.50, "" ];
patternArray[33] = [ "1Up-2Up B", "(4,4)(0,4x)(4,4)(4x,0)", "1Up-2Up B", 0.14, 0.50, "" ];
patternArray[34] = [ "1Up-2Up C", "(4,4)(0,4x)(4,4)(4,0)(4,4)(4x,0)(4,4)(0,4)", "1Up-2Up C", 0.14, 0.50, "" ];
patternArray[35] = [ "1Up-2Up D", "(4,4)(0,4x)(4,4)(4x,0)", "1Up-2Up D", 0.14, 0.50, "" ];
patternArray[36] = [ "1Up-2Up E", "(4,4)(0,4)", "1Up-2Up E", 0.14, 0.50, "" ];
patternArray[37] = [ "1Up-2Up F", "(6,6)(0,2x)(4x,0)", "1Up-2Up F", 0.10, 0.50, "" ];
patternArray[38] = [ "Yo-Yo", "(4,2)", "Yo-Yo", 0.13, 0.50, "" ];
patternArray[39] = [ "Oy-Oy", "(4,2)", "Oy-Oy", 0.13, 0.50, "" ];
patternArray[40] = [ "Around The Yo-Yo", "(4,2)", "Around The Yo-Yo", 0.13, 0.50, "" ];
patternArray[41] = [ "Yo-Yo (Columns Fake A)", "(2,4)", "Yo-Yo (Columns Fake A)", 0.14, 0.50, "" ];
patternArray[42] = [ "Yo-Yo (Columns Fake B)", "(2,4)", "Yo-Yo (Columns Fake B)", 0.14, 0.50, "" ];
patternArray[43] = [ "Yo-Yo (Columns Fake C)", "(4,2)(4x,2)(2,4)(2,4x)", "Yo-Yo (Columns Fake C)", 0.13, 0.50, "" ];
patternArray[44] = [ "Yo-Yo (Tornado)", "(2,4)", "Yo-Yo (Tornado)", 0.20, 0.50, "" ];
patternArray[45] = [ "Yo-Yo (Traverse)", "(2,4)", "Yo-Yo (Traverse)", 0.20, 0.50, "" ];
patternArray[46] = [ "Frankenstein", "(2,4x)(4x,2)", "Frankenstein", 0.20, 0.50, "" ];
patternArray[47] = [ "441", "441", "Normal", 0.13, 0.50, "" ];
patternArray[48] = [ "Outside 441", "441", "Outside 441", 0.13, 0.50, "" ];
patternArray[49] = [ "Arches", "3", "Arches", 0.13, 0.50, "" ];
patternArray[50] = [ "2-in-1-Hand Tennis", "(2,4)(2,4x)(4,2)(4x,2)", "2-in-1-Hand Tennis", 0.14, 0.50, "" ];
patternArray[51] = [ "Statue of Liberty A", "3", "Statue of Liberty A", 0.12, 0.50, "" ];
patternArray[52] = [ "Statue of Liberty B", "3", "Statue of Liberty B", 0.14, 0.50, "" ];
patternArray[53] = [ "Shuffle", "(4x,2x)", "Shuffle", 0.12, 0.50, "" ];
patternArray[54] = [ "See Saw Shuffle (Luke's Shuffle)", "(4,2x)(2x,4)", "See Saw Shuffle", 0.15, 0.50, "" ];
patternArray[55] = [ "441 Shuffle", "441", "441 Shuffle", 0.10, 0.50, "" ];
patternArray[56] = [ "Both Side Slam", "(4x,2x)(2,4x)(2x,4x)(4x,2)", "Both Side Slam", 0.10, 0.50, "" ];
patternArray[57] = [ "Robot (Machine,Factory)", "242334", "Robot", 0.15, 0.50, "" ];
patternArray[58] = [ "Exchange (Pendulum,Drop)", "2334", "Exchange", 0.13, 0.50, "" ];
patternArray[59] = [ "Carry", "3", "Carry", 0.10, 0.50, "" ];
patternArray[60] = [ "Weave", "(2,4)(2,4x)(4,2)(4x,2)", "Weave", 0.10, 0.50, "" ];
patternArray[61] = [ "Follow", "423", "Follow", 0.10, 0.50, "" ];
patternArray[62] = [ "Burke's Barrage", "423", "Burke's Barrage", 0.10, 0.50, "" ];
patternArray[63] = [ "Rubenstein's Revenge", "35223", "Rubenstein's Revenge", 0.15, 0.50, "" ];
groups[4] = [ "3-ball Tricks ", 32, 63 ];
patternArray[64] = [ "4-Reverse Synchronous Fountain", "(4,4)", "Reverse", 0.20, 0.50, "" ];
patternArray[65] = [ "4-Reverse Fountain", "4", "Reverse", 0.20, 0.50, "" ];
patternArray[66] = [ "4-Columns Switch", "(4x,4x)(4,4)", "4-Columns Switch", 0.20, 0.50, "" ];
patternArray[67] = [ "4-ball Cross A", "(4x,4x)", "4-ball Cross A", 0.20, 0.50, "" ];
patternArray[68] = [ "4-ball Cross B", "(4x,4x)", "4-ball Cross B", 0.20, 0.50, "" ];
patternArray[69] = [ "444447333", "444447333", "Normal", 0.30, 0.50, "" ];
patternArray[70] = [ "4-ball Tennis", "44453", "4-ball Tennis", 0.20, 0.50, "" ];
patternArray[71] = [ "4-ball Burke's Barrage", "525", "4-ball Burke's Barrage", 0.12, 0.50, "" ];
patternArray[72] = [ "Dancey's Devilment", "3[53]22[32]", "Rubenstein's Revenge", 0.15, 0.50, "" ];
groups[5] = [ "4-ball Tricks ", 64, 72 ];
patternArray[73] = [ "5-Reverse Cascade", "5", "Reverse", 0.20, 0.50, "" ];
patternArray[74] = [ "3-Cascade -> 5-Cascade", "[32][32][32][32][32][32][52][52][52]555555522", "Normal", 0.20, 0.50, "" ];
patternArray[75] = [ "555555744", "555555744", "Normal", 0.30, 0.50, "" ];
patternArray[76] = [ "5-ball Burke's Barrage", "726", "5-ball Burke's Barrage", 0.12, 0.50, "" ];
groups[6] = [ "5-ball Tricks ", 73, 76 ];
patternArray[77] = [ "3-Shower Japanese OTEDAMA", "51", "Shower", 0.20, 0.50, "" ];
patternArray[78] = [ "3-Half Shower A", "5223", "Shower", 0.20, 0.50, "" ];
patternArray[79] = [ "3-Half Shower B", "3", "Odd Half", 0.20, 0.50, "" ];
patternArray[80] = [ "4-Shower", "71", "Shower", 0.20, 0.50, "" ];
patternArray[81] = [ "4-Half Shower", "53", "Shower", 0.20, 0.50, "" ];
patternArray[82] = [ "5-Half Shower A", "5", "Odd Half", 0.20, 0.50, "" ];
patternArray[83] = [ "5-Half Shower B", "73", "Shower", 0.20, 0.50, "" ];
patternArray[84] = [ "5-Half Shower C", "(4x,6x)", "Shower", 0.20, 0.50, "" ];
patternArray[85] = [ "6-Half Shower", "75", "Shower", 0.20, 0.50, "" ];
patternArray[86] = [ "3-Synchronous Shower", "(2x,4x)", "Shower", 0.20, 0.50, "" ];
patternArray[87] = [ "4-Synchronous Shower", "(2x,6x)", "Shower", 0.20, 0.50, "" ];
patternArray[88] = [ "3-High-Low Shower A", "7131", "Shower", 0.20, 0.50, "" ];
patternArray[89] = [ "3-High-Low Shower B", "(2x,6x)(2x,2x)", "3-High-Low Shower B", 0.15, 0.50, "" ];
patternArray[90] = [ "3-High-Low Shower C", "315171", "Shower", 0.20, 0.50, "" ];
patternArray[91] = [ "4-High-Low Shower", "9151", "Shower", 0.20, 0.50, "" ];
patternArray[92] = [ "Under-the-Hand Shower", "(2x,4x)", "Under-the-Hand Shower", 0.20, 0.50, "" ];
patternArray[93] = [ "Under-the-Hand Shower (alternate)", "(2x,4x)", "Under-the-Hand Shower 2", 0.20, 0.50, "" ];
patternArray[94] = [ "4-Half Shower Like Trick", "35", "Odd Half", 0.20, 0.50, "" ];
patternArray[95] = [ "High Half Shower", "3", "High Half Shower", 0.12, 0.50, "" ];
patternArray[96] = [ "Multi-Shower", "[97]121", "Shower", 0.20, 0.50, "" ];
patternArray[97] = [ "False Shower (Windmill)", "3", "False Shower", 0.20, 0.50, "" ];
groups[7] = [ "Shower ", 77, 97 ];
patternArray[98] = [ "2 out of the Mills Mess", "330", "Mills Mess", 0.13, 0.50, "" ];
patternArray[99] = [ "Standard Mills Mess", "3", "Mills Mess", 0.13, 0.50, "" ];
patternArray[100] = [ "423", "423", "Mills Mess", 0.20, 0.50, "" ];
patternArray[101] = [ "414", "414", "Mills Mess", 0.20, 0.50, "" ];
patternArray[102] = [ "315", "315", "Mills Mess", 0.20, 0.50, "" ];
patternArray[103] = [ "Mills Mess Box", "612", "Mills Mess", 0.20, 0.50, "" ];
patternArray[104] = [ "4-ball Mills Mess", "4", "Mills Mess", 0.20, 0.50, "" ];
patternArray[105] = [ "534", "534", "Mills Mess", 0.20, 0.50, "" ];
patternArray[106] = [ "552", "552", "Mills Mess", 0.20, 0.50, "" ];
patternArray[107] = [ "642", "642", "Mills Mess", 0.20, 0.50, "" ];
patternArray[108] = [ "5-ball Mills Mess", "5", "Mills Mess", 0.20, 0.50, "" ];
patternArray[109] = [ "6-ball Mills Mess", "6", "Mills Mess", 0.20, 0.50, "" ];
patternArray[110] = [ "864", "864", "Mills Mess", 0.20, 0.50, "" ];
patternArray[111] = [ "Multi-Mills Mess", "[34]23", "Mills Mess", 0.20, 0.50, "" ];
patternArray[112] = [ "No Through Mills Mess", "3", "No Through Mills Mess", 0.08, 0.50, "" ];
patternArray[113] = [ "Half Mess", "3", "Half Mess", 0.13, 0.50, "" ];
patternArray[114] = [ "Reverse Mills Mess", "3", "Reverse Mills Mess", 0.13, 0.50, "" ];
patternArray[115] = [ "Mills 44133", "44133", "Mills 44133", 0.13, 0.50, "" ];
patternArray[116] = [ "2-Mills Simultaneous", "(4x,4x)(0,0)", "Mills Simultaneous", 0.06, 0.75, "" ];
patternArray[117] = [ "3-Mills Simultaneous", "(4x,4x)(4x,0)(4x,4x)(0,4x)", "Mills Simultaneous", 0.06, 0.75, "" ];
patternArray[118] = [ "4-Mills Simultaneous", "(4x,4x)", "Mills Simultaneous", 0.06, 0.75, "" ];
groups[8] = [ "Mills Mess ", 98, 118 ];
patternArray[119] = [ "2 Balls out of the Box", "(2x,4)(0,2x)", "Box", 0.12, 0.50, "" ];
patternArray[120] = [ "Box (See Saw)", "(2x,4)(4,2x)", "Box", 0.12, 0.50, "" ];
patternArray[121] = [ "A Box-like Pattern A", "126", "Normal", 0.15, 0.50, "" ];
patternArray[122] = [ "A Box-like Pattern B", "630", "Normal", 0.15, 0.50, "" ];
patternArray[123] = [ "Double Box", "(4x,2x)(4,2x)(2x,4x)(2x,4)", "Double Box", 0.11, 0.50, "" ];
patternArray[124] = [ "Advanced Box", "(2x,8)(2x,4)(0,2x)(8,2x)(4,2x)(2x,0)", "Advanced Box", 0.20, 0.50, "" ];
patternArray[125] = [ "4-ball Box A", "633", "4-ball Box A", 0.20, 0.50, "" ];
patternArray[126] = [ "4-ball Box B", "(2x,8)(2x,4)(8,2x)(4,2x)", "4-ball Box B", 0.20, 0.50, "" ];
patternArray[127] = [ "4-ball Box C", "(2x,6)(2x,6)(6,2x)(6,2x)", "Normal", 0.20, 0.50, "" ];
patternArray[128] = [ "5-ball Box", "(4x,6)(6,4x)", "Normal", 0.20, 0.50, "" ];
groups[9] = [ "Box ", 119, 128 ];
patternArray[129] = [ "Boston Mess A", "3", "Boston Mess A", 0.20, 0.50, "" ];
patternArray[130] = [ "Boston Mess B", "3", "Boston Mess B", 0.20, 0.50, "" ];
patternArray[131] = [ "4-Columns (Pistons)", "4", "4-Columns (Asymmetry)", 0.20, 0.50, "" ];
patternArray[132] = [ "4-Synchronous Columns (Asymmetry)", "(4,4)", "4-Columns (Asymmetry)", 0.20, 0.50, "" ];
patternArray[133] = [ "4-Synchronous Columns (Symmetry)", "(4,4)", "4-Columns (Symmetry)", 0.20, 0.50, "" ];
patternArray[134] = [ "4-Synchronous Columns (Splits)", "(4,4)", "4-Columns (Splits)", 0.20, 0.50, "" ];
patternArray[135] = [ "5-Columns", "(6,6)(6,6)(0,6)", "5-Columns", 0.20, 0.50, "" ];
patternArray[136] = [ "5-Mills Mess Columns A", "5", "5-Mills Mess Columns A", 0.20, 0.50, "" ];
patternArray[137] = [ "5-Mills Mess Columns B", "5", "5-Mills Mess Columns B", 0.20, 0.50, "" ];
patternArray[138] = [ "5-Mills Mess Columns C", "5", "5-Mills Mess Columns C", 0.20, 0.50, "" ];
patternArray[139] = [ "6-Columns", "(6,6)", "6-Columns", 0.20, 0.50, "" ];
patternArray[140] = [ "Multi-Columns", "([46],[46])(0,6)(2,2)", "Multi-Columns", 0.20, 0.50, "" ];
groups[10] = [ "Columns ", 129, 140 ];
patternArray[141] = [ "2-ball Columns in One Hand", "40", "2-ball Columns in One Hand", 0.20, 0.50, "" ];
patternArray[142] = [ "3-ball in One Hand", "60", "Normal", 0.20, 0.50, "" ];
patternArray[143] = [ "3-Multiplex in One Hand", "[46]06020", "Normal", 0.20, 0.50, "" ];
patternArray[144] = [ "Combination in One Hand", "[46]06020[46]06020[46]060606060606020", "Normal", 0.20, 0.50, "" ];
patternArray[145] = [ "8040", "8040", "Normal", 0.20, 0.50, "" ];
patternArray[146] = [ "3-ball Columns in One Hand", "60", "3-ball Columns in One Hand", 0.20, 0.50, "" ];
patternArray[147] = [ "Cascade in One Hand", "60", "Cascade in One Hand", 0.20, 0.50, "" ];
groups[11] = [ "One Hand Tricks ", 141, 147 ];
patternArray[148] = [ "3-ball Combination", "33333333333333535051515151515151512441441441441441441", "Normal", 0.20, 0.50, "" ];
patternArray[149] = [ "53", "53", "Normal", 0.20, 0.50, "" ];
patternArray[150] = [ "44453", "44453", "Normal", 0.20, 0.50, "" ];
patternArray[151] = [ "501", "501", "Normal", 0.20, 0.50, "" ];
patternArray[152] = [ "531", "531", "Normal", 0.20, 0.50, "" ];
patternArray[153] = [ "561", "561", "Normal", 0.20, 0.50, "" ];
patternArray[154] = [ "Complete Waste of a 5 Ball Juggler", "450", "Normal", 0.20, 0.50, "" ];
patternArray[155] = [ "453", "453", "Normal", 0.20, 0.50, "" ];
patternArray[156] = [ "720", "720", "Normal", 0.20, 0.50, "" ];
patternArray[157] = [ "753", "753", "Normal", 0.20, 0.50, "" ];
patternArray[158] = [ "741", "741", "Normal", 0.20, 0.50, "" ];
patternArray[159] = [ "744", "744", "Normal", 0.20, 0.50, "" ];
patternArray[160] = [ "6424", "6424", "Normal", 0.20, 0.50, "" ];
patternArray[161] = [ "64", "64", "Normal", 0.20, 0.50, "" ];
patternArray[162] = [ "66661", "66661", "Normal", 0.20, 0.50, "" ];
patternArray[163] = [ "61616", "61616", "Normal", 0.20, 0.50, "" ];
patternArray[164] = [ "(5201) & (0040)", "5241", "Normal", 0.20, 0.50, "" ];
patternArray[165] = [ "(70300) & (02012)", "72312", "Normal", 0.20, 0.50, "" ];
patternArray[166] = [ "7272712", "7272712", "Normal", 0.20, 0.50, "" ];
patternArray[167] = [ "51414", "51414", "Normal", 0.20, 0.50, "" ];
patternArray[168] = [ "7161616", "7161616", "Normal", 0.20, 0.50, "" ];
patternArray[169] = [ "88333", "88333", "Normal", 0.20, 0.50, "" ];
patternArray[170] = [ "75751", "75751", "Normal", 0.20, 0.50, "" ];
patternArray[171] = [ "123456789", "123456789", "Center", 0.20, 0.50, "" ];
groups[12] = [ "Site Swaps ", 148, 171 ];
patternArray[172] = [ "[34]1", "[34]1", "Normal", 0.20, 0.50, "" ];
patternArray[173] = [ "4[43]1 441+1", "4[43]1", "Normal", 0.20, 0.50, "" ];
patternArray[174] = [ "Martin", "[62]25", "Martin", 0.20, 0.50, "" ];
patternArray[175] = [ "5-ball Multiplex A step_1", "[22]5[22]0[54]020", "Normal", 0.20, 0.50, "" ];
patternArray[176] = [ "5-ball Multiplex A step_2", "[54]225[22]2", "Normal", 0.20, 0.50, "" ];
patternArray[177] = [ "5-ball Multiplex A", "[54][22]2", "Normal", 0.20, 0.50, "" ];
patternArray[178] = [ "5-Cascade -> 5-Multiplex A No.1", "555555522[54][22]2[54][22]2[54][22]2[52][52][52]", "Normal", 0.20, 0.50, "" ];
patternArray[179] = [ "5-Cascade -> 5-Multiplex A No.2", "555555522[54][22]2[54][22]2[54][22]2[54][52]", "Normal", 0.20, 0.50, "" ];
patternArray[180] = [ "5-ball Multiplex B step_1", "[54]24522", "Normal", 0.20, 0.50, "" ];
patternArray[181] = [ "5-ball Multiplex B", "24[54]", "Normal", 0.20, 0.50, "" ];
patternArray[182] = [ "25[75]51", "25[75]51", "Normal", 0.20, 0.50, "" ];
patternArray[183] = [ "7-ball Splits A", "[456][22]2", "Normal", 0.20, 0.50, "" ];
patternArray[184] = [ "7-ball Splits B", "([66x],2)(2,[66x])", "Center", 0.20, 0.50, "" ];
patternArray[185] = [ "26[76]", "26[76]", "Normal", 0.20, 0.50, "" ];
patternArray[186] = [ "[234]57", "[234]57", "Normal", 0.20, 0.50, "" ];
patternArray[187] = [ "9-ball Multiplex", "[54]", "Normal", 0.20, 0.50, "" ];
groups[13] = [ "Multiplex ", 172, 187 ];
patternArray[188] = [ "3-Synchronous Cascade A", "(2,4x)(4x,2)", "Normal", 0.20, 0.50, "" ];
patternArray[189] = [ "3-Synchronous Cascade B", "(0,6x)(6x,0)", "Normal", 0.20, 0.50, "" ];
patternArray[190] = [ "(2x,6x)(6x,2x)", "(2x,6x)(6x,2x)", "Normal", 0.20, 0.50, "" ];
patternArray[191] = [ "(4x,2x)(2,4)", "(4x,2x)(2,4)", "Normal", 0.20, 0.50, "" ];
patternArray[192] = [ "(4x,6)(0,2x)", "(4x,6)(0,2x)", "Normal", 0.20, 0.50, "" ];
patternArray[193] = [ "(2,6x)(2x,6)(6x,2)(6,2x)", "(2,6x)(2x,6)(6x,2)(6,2x)", "Normal", 0.20, 0.50, "" ];
patternArray[194] = [ "(2,4)([44x],2x)", "(2,4)([44x],2x)", "Normal", 0.20, 0.50, "" ];
patternArray[195] = [ "(2,[62])([22],6x)([62],2)(6x,[22])", "(2,[62])([22],6x)([62],2)(6x,[22])", "Normal", 0.20, 0.50, "" ];
patternArray[196] = [ "6-ball Synchronous Fountain", "(6,6)", "Normal", 0.20, 0.50, "" ];
groups[14] = [ "Synchronous ", 188, 196 ];
patternArray[197] = [ "5-Shower", "91", "Shower", 0.20, 0.50, "" ];
patternArray[198] = [ "6-Shower", "b1", "Shower", 0.20, 0.50, "" ];
patternArray[199] = [ "7-Shower  IJA Champion", "d1", "Shower", 0.20, 0.50, "" ];
patternArray[200] = [ "6-Fountain", "6", "Normal", 0.20, 0.50, "" ];
patternArray[201] = [ "7-Cascade", "7", "Normal", 0.20, 0.50, "" ];
patternArray[202] = [ "8-Fountain", "8", "Normal", 0.20, 0.50, "" ];
patternArray[203] = [ "9-Cascade  IJA Champion", "9", "Normal", 0.20, 0.50, "" ];
groups[15] = [ "Numbers ", 197, 203 ];
patternArray[204] = [ "35-Cascade", "z", "Normal", 0.20, 0.50, "" ];
patternArray[205] = [ "18-Shower", "z1", "Shower", 0.20, 0.50, "" ];
patternArray[206] = [ "35-Multi Shower", "[9bdfh][11111]", "Shower", 0.20, 0.50, "" ];
patternArray[207] = [ "12-Mills Mess", "333666999cccfffiiilll", "Mills Mess", 0.20, 0.50, "" ];
patternArray[208] = [ "[b9753]0020[22]0[222]0[2222]0", "[b9753]0020[22]0[222]0[2222]0", "Normal", 0.20, 0.50, "" ];
patternArray[209] = [ "123456789abcdefghijklmnopqrstuvwxyz", "123456789abcdefghijklmnopqrstuvwxyz", "Center", 0.20, 0.50, "" ];
patternArray[210] = [ "9-ball Box", "u1q1m1i1e1a1612", "9-ball Box", 0.20, 0.50, "" ];
patternArray[211] = [ "trpnljhfdb97531", "trpnljhfdb97531", "Normal", 0.20, 0.50, "" ];
patternArray[212] = [ "Ken", "Ken", "Normal", 0.20, 0.50, "" ];
patternArray[213] = [ "x", "x", "Normal", 0.20, 0.50, "" ];
patternArray[214] = [ "zxv", "zxv", "Normal", 0.20, 0.50, "" ];
patternArray[215] = [ "zxvtrpnljhfdb97531", "zxvtrpnljhfdb97531", "Normal", 0.20, 0.50, "" ];
patternArray[216] = [ "Penta-Multiplex", "[56789]", "Normal", 0.20, 0.50, "" ];
patternArray[217] = [ "20-Columns A", "(k,k)", "20-Columns A", 0.10, 0.50, "" ];
patternArray[218] = [ "20-Columns B", "k", "20-Columns B", 0.10, 0.50, "" ];
groups[16] = [ "Are You God? ", 204, 218 ];
patternArray[219] = [ "magnus. What Isaac says jugglemaster can't do.", "[ma][gnu]s", "Normal", 0.17, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[220] = [ "Church Windows", "(0,4x)(4x,4x)(4x,0)(4x,4x)", "Church Windows", 0.14, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[221] = [ "Hunchback of Notre Dam", "(0,4x)(4,4)(4x,0)(4,4)", "Hunchback", 0.14, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[222] = [ "Hunchback's brother", "(0,4x)(4,4)(4x,0)(4,4)", "Hunchback's Brother", 0.14, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[223] = [ "575151", "575151", "Center", 0.10, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[224] = [ "7141404", "7141404", "Center", 0.10, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[225] = [ "(8x,2)(8,8)(8,8)(2,8x)(8,8)(8,8)", "(8x,2)(8,8)(8,8)(2,8x)(8,8)(8,8)", "Center", 0.10, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[226] = [ "(6x,2)(6,6)(2,6x)(6,6)", "(6x,2)(6,6)(2,6x)(6,6)", "Center", 0.10, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[227] = [ "([6x6],2)(2,[6x6])", "([6x6],2)(2,[6x6])", "Center", 0.10, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[228] = [ "56702", "56702", "Center", 0.10, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[229] = [ "64244", "64244", "Center", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[230] = [ "[34]", "[34]", "Center", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[231] = [ "4-ball Box variation 1", "(6x,2x)(2x,6x)", "Normal", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[232] = [ "4-ball Box variation 2", "(6x,4x)(2x,4x)(4x,6x)(4x,2x)", "Normal", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[233] = [ "[34]1", "[34]1", "Normal", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[234] = [ "4[43]1 441+1", "4[43]1", "Normal", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[235] = [ "6316131 ", "6316131 ", "Normal", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[236] = [ "55514", "55514", "Center", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[237] = [ "12345", "12345", "Center", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[238] = [ "23456", "23456", "Center", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[239] = [ "1234567", "1234567", "Center", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[240] = [ "633", "633", "Center", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[241] = [ "44633", "44633", "Center", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[242] = [ "44444444633", "44444444633", "Center", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[243] = [ "64514", "64514", "Center", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[244] = [ "5 Ball Tennis", "5555744", "5 Ball Tennis", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[245] = [ "1 up 4 up (standard)", "([46],[46])(6x,0)(2,2)([46],[46])(0,6x)(2,2)", "1 up 4 up (standard)", 0.14, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[246] = [ "1 up 4 up (flashy)", "([4x6x],[4x6x])(6x,0)(2,2)([4x6x],[4x6x])(0,6x)(2,2)", "1 up 4 up (flashy)", 0.14, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[247] = [ "2 up 4 up (standard)", "([46],[46])(6,6)(2,2)", "2 up 4 up (standard)", 0.14, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[248] = [ "2 up 4 up (flashy)", "([4x6x],[4x6x])(6,6)(2,2)", "2 up 4 up (flashy)", 0.14, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[249] = [ "Orr Multiplex (3 balls)", "(0,[4x4])(0,4x)(2,4x)([4x4],0)(4x,0)(4x,2)", "Normal", 0.10, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[250] = [ "Orr Multiplex (4 balls)", "(6x,[4x4])(0,4x)(2,4x)([4x4],6x)(4x,0)(4x,2)", "Normal", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[251] = [ "Orr Multiplex underhand", "(6x,[4x4])(0,4x)(2,4x)([4x4],6x)(4x,0)(4x,2)", "Orr Multiplex", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[252] = [ "Reverse Orr Multiplex", "(6x,[4x4])(0,4x)(2,4x)([4x4],6x)(4x,0)(4x,2)", "Reverse", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[253] = [ "Macdonalds!", "([yxy],2)(2,[22])(2,[yxy])([22],2)", "Center", 0.04, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[254] = [ "Wide Half Shower 5", "73", "Wide Shower", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[255] = [ "Wide Half Shower 4", "53", "Wide Shower", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[256] = [ "0123456", "0123456", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[257] = [ "0303456", "0303456", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[258] = [ "63123", "63123", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[259] = [ "6051", "6051", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[260] = [ "63303", "63303", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[261] = [ "64113", "64113", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[262] = [ "70161", "70161", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[263] = [ "83031", "83031", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[264] = [ "6316131", "6316131", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[265] = [ "612", "612", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[266] = [ "62313", "62313", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[267] = [ "63141", "63141", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[268] = [ "52413", "52413", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[269] = [ "63501", "63501", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[270] = [ "3 in 1 hand", "60", "Center", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[271] = [ "3 Hi-Lo in 1 hand", "8040", "Center", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[272] = [ "Alternate 3 Balls in Hand A ", "123456060606060", "Center", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[273] = [ "Alternate 3 Balls in Hand B", "303456060606060", "Center", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[274] = [ "Alternate 3 Balls in Hand C", "6161601", "Center", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[275] = [ "56162", "56162", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[276] = [ "6451", "6451", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[277] = [ "5641", "5641", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[278] = [ "5560", "5560", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[279] = [ "6352", "6352", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[280] = [ "83333", "83333", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[281] = [ "845151", "845151", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[282] = [ "83441", "83441", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[283] = [ "83531", "83531", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[284] = [ "83522", "83522", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[285] = [ "7522", "7522", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[286] = [ "83423", "83423", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[287] = [ "7423", "7423", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[288] = [ "804", "804", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[289] = [ "36362", "36362", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[290] = [ "7531", "7531", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[291] = [ "75314", "75314", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[292] = [ "714", "714", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[293] = [ "73334", "73334", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[294] = [ "5911", "5911", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[295] = [ "831", "831", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[296] = [ "7045", "7045", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[297] = [ "73451", "73451", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[298] = [ "7441", "7441", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[299] = [ "74414", "74414", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[300] = [ "642", "642", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[301] = [ "4246", "4246", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[302] = [ "62525", "62525", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[303] = [ "5751613", "5751613", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[304] = [ "673175151", "673175151", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[305] = [ "773151", "773151", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[306] = [ "746151", "746151", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[307] = [ "661515", "661515", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[308] = [ "751515", "751515", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[309] = [ "6631", "6631", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[310] = [ "72461", "72461", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[311] = [ "72416", "72416", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[312] = [ "73631", "73631", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[313] = [ "75661", "75661", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[314] = [ "66314", "66314", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[315] = [ "63524", "63524", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[316] = [ "7405 Transitions A", "4444445574057405740574052", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[317] = [ "7405 Transitions B", "55515574052", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[318] = [ "7405 Transitions C", "5574052", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[319] = [ "7405 Transitions D", "45525574052", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[320] = [ "7405 Transitions E", "55550557405255550", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[321] = [ "555183333", "555183333", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[322] = [ "5551955500", "5551955500", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[323] = [ "5551552", "5551552", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[324] = [ "55255550", "55255550", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[325] = [ "53633", "53633", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[326] = [ "7731514", "7731514", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[327] = [ "7461514", "7461514", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[328] = [ "5661514", "5661514", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[329] = [ "35741", "35741", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[330] = [ "4 Shower Transition 1", "44444567171717123", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[331] = [ "4 Shower Transition 2", "44444747171717141", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[332] = [ "4 Shower Transition 3", "4444456717171716151", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[333] = [ "Alternate 4 Shower 1", "4567123", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[334] = [ "Alternate 4 Shower 2", "7471414", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[335] = [ "Alternate 4 Shower 3", "456716151", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[336] = [ "4 Shower - Three heights", "915171", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[337] = [ "4 Shower - Crazy", "747191517141", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[338] = [ "4 Shower with a leak", "717181717170", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[339] = [ "1 up 1 across", "8170", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[340] = [ "Playing catch", "7333444", "Reverse", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[341] = [ "Looking idle", "7272712", "Reverse", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[342] = [ "5 Balls Peter Gunn", "33536479", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[343] = [ "726", "726", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[344] = [ "7346", "7346", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[345] = [ "7463", "7463", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[346] = [ "663", "663", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[347] = [ "88441", "88441", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[348] = [ "88531", "88531", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[349] = [ "8444", "8444", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[350] = [ "8534", "8534", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[351] = [ "8633", "8633", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[352] = [ "84445", "84445", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[353] = [ "85345", "85345", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[354] = [ "94444", "94444", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[355] = [ "94534", "94534", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[356] = [ "96451", "96451", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[357] = [ "95551", "95551", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[358] = [ "96631", "96631", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[359] = [ "753", "753", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[360] = [ "771", "771", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[361] = [ "861", "861", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[362] = [ "64", "64", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[363] = [ "645", "645", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[364] = [ "6662", "6662", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[365] = [ "75661", "75661", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[366] = [ "756615", "756615", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[367] = [ "7562", "7562", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[368] = [ "777171", "777171", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[369] = [ "Full 5 Flash", "5555557777700", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[370] = [ "3 of 5 Flash", "55555577722", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[371] = [ "97531", "97531", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[372] = [ "x", "x", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[373] = [ "zxv", "zxv", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[374] = [ "Itzik", "Itzik", "Wide", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[375] = [ "Orr", "Orr", "Wide", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[376] = [ "(6x,2)(6,6)(2,6x)(6,6)", "(6x,2)(6,6)(2,6x)(6,6)", "Center", 0.10, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[377] = [ "Wide Half Shower", "73", "Wide Shower", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[378] = [ "Tennis", "5555744", "5 Ball Tennis", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[379] = [ "1 up 4 up (standard)", "([46],[46])(6x,0)(2,2)([46],[46])(0,6x)(2,2)", "1 up 4 up (standard)", 0.14, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[380] = [ "1 up 4 up (flashy)", "([4x6x],[4x6x])(6x,0)(2,2)([4x6x],[4x6x])(0,6x)(2,2)", "1 up 4 up (flashy)", 0.14, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
groups[17] = [ "Tricks by Isaac Orr ", 219, 380 ];
patternArray[381] = [ "6 balls: 909", "909", "Normal", 0.17, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[382] = [ "2 up 4 up (standard)", "([46],[46])(6,6)(2,2)", "2 up 4 up (standard)", 0.17, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[383] = [ "2 up 4 up (flashy)", "([4x6x],[4x6x])(6,6)(2,2)", "2 up 4 up (flashy)", 0.17, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[384] = [ "7 Balls Volcano A", "(8x,2)(8,8)(8,8)(2,8x)(8,8)(8,8)", "Center", 0.10, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[385] = [ "7 Balls Volcano B", "([6x6],2)(2,[6x6])", "Center", 0.10, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[386] = [ "7 Balls: [34]", "[34]", "Center", 0.20, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[387] = [ "19 Balls: Macdonalds!", "([yxy],2)(2,[22])(2,[yxy])([22],2)", "Center", 0.04, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[388] = [ "24 Balls: Itzik", "Itzik", "Wide", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[389] = [ "26 Balls: Orr", "Orr", "Wide", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[390] = [ "33 Balls: zxv", "zxv", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
patternArray[391] = [ "33 Balls: x", "x", "Normal", 0.15, 0.50, "Isaac Orr (http://www.juggler.net/orr/)" ];
groups[18] = [ "Tough tricks by Isaac Orr ", 381, 391 ];
patternArray[392] = [ "JMpatt (4x,2x)", "(4x,2x)", "JMpatt(4x2x)", 0.17, 0.50, "" ];
patternArray[393] = [ "JMpatt 3333", "3333", "JMpatt(3333)", 0.17, 0.50, "" ];
patternArray[394] = [ "JMpatt 51414 I", "51414", "JMpatt(5141)a", 0.17, 0.50, "" ];
patternArray[395] = [ "JMpatt 51414 II", "51414", "JMpatt(5141)b", 0.17, 0.50, "" ];
groups[19] = [ "Tricks by JMpatt ", 392, 395 ];
patternArray[396] = [ "3-Balls Peter Gunn Theme", "11314257", "Normal", 0.17, 0.50, "JAG (http://www.bogleg.com/)" ];
patternArray[397] = [ "5-Balls Peter Gunn Theme", "33536479", "Normal", 0.17, 0.50, "JAG (http://www.bogleg.com/)" ];
groups[20] = [ "Tricks by JAG ", 396, 397 ];
patternArray[398] = [ "5 ball Easier Variation", "[54][22]2", "Mills Mess", 0.16, 0.50, "" ];
patternArray[399] = [ "5 ball Harder Variation", "24[54]", "Mills Mess", 0.16, 0.50, "" ];
patternArray[400] = [ "4 Ball (Orinoco)", "[34]23", "Mills Mess", 0.10, 0.50, "" ];
patternArray[401] = [ "Orr Multiplex MM", "(6x,[4x4])(0,4x)(2,4x)([4x4],6x)(4x,0)(4x,2)", "Orr Multiplex MM", 0.10, 0.50, "" ];
groups[21] = [ "Multiplex mills mess ", 398, 401 ];
patternArray[402] = [ "Upside-down Tennis", "3", "Upside-down Tennis", 0.17, 0.50, "Toshiyuki Mori (http://www.egroups.co.jp/files/patio-ml/en/jugglemaster.html)" ];
patternArray[403] = [ "Upside-down Tennis2", "3", "Upside-down Tennis2", 0.17, 0.50, "Toshiyuki Mori (http://www.egroups.co.jp/files/patio-ml/en/jugglemaster.html)" ];
patternArray[404] = [ "Similar to Fastest Trick", "612", "Seon", 0.04, 0.50, "Toshiyuki Mori (http://www.egroups.co.jp/files/patio-ml/en/jugglemaster.html)" ];
patternArray[405] = [ "The Fastest Trick in the World", "423", "The Fastest Trick in the World", 0.04, 0.50, "Toshiyuki Mori (http://www.egroups.co.jp/files/patio-ml/en/jugglemaster.html)" ];
patternArray[406] = [ "Happy Cascade", "(2,4x)(4x,2)", "Happy", 0.15, 0.60, "Toshiyuki Mori (http://www.egroups.co.jp/files/patio-ml/en/jugglemaster.html)" ];
patternArray[407] = [ "5 Ball Happy Cascade", "(2,8x)(8x,2)", "Happy", 0.15, 0.60, "Toshiyuki Mori (http://www.egroups.co.jp/files/patio-ml/en/jugglemaster.html)" ];
patternArray[408] = [ "Exercise", "(2,2)", "Exercise", 0.15, 0.60, "Toshiyuki Mori (http://www.egroups.co.jp/files/patio-ml/en/jugglemaster.html)" ];
patternArray[409] = [ "Dance", "(2,2)", "Dance", 0.15, 0.60, "Toshiyuki Mori (http://www.egroups.co.jp/files/patio-ml/en/jugglemaster.html)" ];
patternArray[410] = [ "Flags", "(2,2)", "Flags", 0.15, 0.60, "Toshiyuki Mori (http://www.egroups.co.jp/files/patio-ml/en/jugglemaster.html)" ];
groups[22] = [ "Patterns By Toshiyuki Mori ", 402, 410 ];
patternArray[411] = [ "PWN1", "(4,4)(6,6)(2x,2x)", "pwn", 0.17, 0.50, "Beardy Paul <luap@icculus.org>" ];
patternArray[412] = [ "Half Norton's Demultiplexer", "[35]521", "pwn2", 0.17, 0.50, "Beardy Paul <luap@icculus.org>" ];
patternArray[413] = [ "Norton's Messy 4s", "([44x],[44x])", "pwn3", 0.17, 0.50, "Beardy Paul <luap@icculus.org>" ];
patternArray[414] = [ "3/4 Norton's Demultiplexer", "1[35]0", "Center", 0.17, 0.50, "Beardy Paul <luap@icculus.org>" ];
patternArray[415] = [ "[34]", "[34]", "Center", 0.17, 0.50, "Beardy Paul <luap@icculus.org>" ];
patternArray[416] = [ "23[34]", "23[34]", "Center", 0.17, 0.50, "Beardy Paul <luap@icculus.org>" ];
patternArray[417] = [ "20[34]", "20[34]", "Center", 0.17, 0.50, "Beardy Paul <luap@icculus.org>" ];
patternArray[418] = [ "53[34]", "53[34]", "Center", 0.17, 0.50, "Beardy Paul <luap@icculus.org>" ];
patternArray[419] = [ "26[34]20[34]23[34]", "26[34]20[34]23[34]", "Center", 0.17, 0.50, "Beardy Paul <luap@icculus.org>" ];
patternArray[420] = [ "Back & Forth", "23[22]0[222]0[223]0[23]", "Center", 0.17, 0.50, "Beardy Paul <luap@icculus.org>" ];
patternArray[421] = [ "Back & Forth 2", "333323[22]0[222]0[223]0[23]", "Center", 0.17, 0.50, "Beardy Paul <luap@icculus.org>" ];
patternArray[422] = [ "High Triplex", "[357]0030333323[22]0", "normal", 0.17, 0.50, "Beardy Paul <luap@icculus.org>" ];
patternArray[423] = [ "Low Triplex", "[135]30333323[22]0", "normal", 0.17, 0.50, "Beardy Paul <luap@icculus.org>" ];
groups[23] = [ "Patterns by PWN ", 411, 423 ];
patternArray[424] = [ "55550", "55550", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[425] = [ "5551", "5551", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[426] = [ "552", "552", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[427] = [ "53", "53", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[428] = [ "555504", "555504", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[429] = [ "55514", "55514", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[430] = [ "5524", "5524", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[431] = [ "534", "534", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[432] = [ "666660", "666660", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[433] = [ "66661", "66661", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[434] = [ "6662", "6662", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[435] = [ "663", "663", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[436] = [ "64", "64", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[437] = [ "6666605", "6666605", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[438] = [ "666615", "666615", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[439] = [ "66625", "66625", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[440] = [ "6635", "6635", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[441] = [ "645", "645", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[442] = [ "7777770", "7777770", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[443] = [ "777771", "777771", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[444] = [ "77772", "77772", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[445] = [ "7773", "7773", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[446] = [ "774", "774", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[447] = [ "75", "75", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[448] = [ "77777706", "77777706", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[449] = [ "7777716", "7777716", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[450] = [ "777726", "777726", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[451] = [ "77736", "77736", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[452] = [ "7746", "7746", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[453] = [ "756", "756", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
groups[24] = [ "Interesting groups of tricks ", 424, 453 ];
patternArray[454] = [ "Tims Multi Mill", "6464", "Windmill", 0.17, 0.50, "Tim Andrews (http://ttbomb12.tripod.com)" ];
patternArray[455] = [ "Tims Multi Mess", "6464", "Mills Mess", 0.17, 0.50, "Tim Andrews (http://ttbomb12.tripod.com)" ];
groups[25] = [ "Patterns by Other Authors ", 454, 455 ];
patternArray[456] = [ "Long Name for Pointless Trick", "12340", "Normal", 0.17, 0.50, "Tom Davie <tom.davie@gmail.com>" ];
patternArray[457] = [ "Random Doodle 1", "40303", "Normal", 0.17, 0.50, "Tom Davie <tom.davie@gmail.com>" ];
patternArray[458] = [ "Random Doodle 2", "4040303", "Normal", 0.17, 0.50, "Tom Davie <tom.davie@gmail.com>" ];
patternArray[459] = [ "Ben Beaver at Chocfest", "b444b333444", "Normal", 0.17, 0.50, "Tom Davie <tom.davie@gmail.com>" ];
groups[26] = [ "Patterns By Scotch Tom ", 456, 459 ];
patternArray[460] = [ "531-Extended", "zxvtrpnljhfdb97531", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[461] = [ "Extended Sexta-Multiplex", "[uvwxyz]", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[462] = [ "330-Ball 11-Multiplex [requries fast computer]", "[pqrstuvwxyz]", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[463] = [ "630 Balls", "[123456789abcdefghijklmnopqrstuvwxyz]", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[464] = [ "One-handed Extended Sexta-Multiplex", "[oqsuwy]0", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[465] = [ "Multiplexing Two-handed Shower (Simple)", "[31]", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[466] = [ "Multiplexing Two-handed Shower", "[51]", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[467] = [ "Multiplexing Two-handed Shower (Hard)", "[71]", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[468] = [ "531-Extended Windmill", "zxvtrpnljhfdb97531", "Windmill", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
groups[27] = [ "Stupid Patterns By Chunky Kibbles ", 460, 468 ];
patternArray[469] = [ "abbeys", "abbeys", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[470] = [ "able", "able", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[471] = [ "ably", "ably", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[472] = [ "above", "above", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[473] = [ "aces", "aces", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[474] = [ "acrid", "acrid", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[475] = [ "add", "add", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[476] = [ "address", "address", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[477] = [ "ads", "ads", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[478] = [ "ages", "ages", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[479] = [ "airfoil", "airfoil", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[480] = [ "airmail", "airmail", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[481] = [ "am", "am", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[482] = [ "ambushes", "ambushes", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[483] = [ "amuses", "amuses", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[484] = [ "anger", "anger", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[485] = [ "ante", "ante", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[486] = [ "anti", "anti", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[487] = [ "apple", "apple", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[488] = [ "apply", "apply", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[489] = [ "aqua", "aqua", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[490] = [ "armer", "armer", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[491] = [ "armor", "armor", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[492] = [ "arrow", "arrow", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[493] = [ "as", "as", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[494] = [ "asp", "asp", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[495] = [ "ass", "ass", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[496] = [ "assume", "assume", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[497] = [ "astray", "astray", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[498] = [ "aunt", "aunt", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[499] = [ "auras", "auras", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[500] = [ "awl", "awl", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[501] = [ "bee", "bee", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[502] = [ "behaviors", "behaviors", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[503] = [ "bells", "bells", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[504] = [ "beret", "beret", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[505] = [ "besides", "besides", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[506] = [ "bestow", "bestow", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[507] = [ "bet", "bet", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[508] = [ "bewitch", "bewitch", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[509] = [ "bid", "bid", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[510] = [ "bidder", "bidder", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[511] = [ "big", "big", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[512] = [ "blue", "blue", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[513] = [ "bluffs", "bluffs", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[514] = [ "blurry", "blurry", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[515] = [ "boa", "boa", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[516] = [ "boar", "boar", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[517] = [ "bog", "bog", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[518] = [ "bomb", "bomb", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[519] = [ "boot", "boot", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[520] = [ "borax", "borax", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[521] = [ "bowl", "bowl", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[522] = [ "boy", "boy", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[523] = [ "boyish", "boyish", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[524] = [ "bra", "bra", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[525] = [ "brassy", "brassy", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[526] = [ "brief", "brief", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[527] = [ "brigs", "brigs", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[528] = [ "bud", "bud", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[529] = [ "bug", "bug", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[530] = [ "bum", "bum", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[531] = [ "bus", "bus", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[532] = [ "busboy", "busboy", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[533] = [ "buy", "buy", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[534] = [ "cab", "cab", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[535] = [ "cache", "cache", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[536] = [ "cage", "cage", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[537] = [ "cake", "cake", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[538] = [ "can", "can", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[539] = [ "canvas", "canvas", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[540] = [ "carry", "carry", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[541] = [ "case", "case", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[542] = [ "cat", "cat", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[543] = [ "caught", "caught", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[544] = [ "caw", "caw", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[545] = [ "cellars", "cellars", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[546] = [ "check", "check", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[547] = [ "chilliness", "chilliness", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[548] = [ "chink", "chink", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[549] = [ "chock", "chock", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[550] = [ "civil", "civil", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[551] = [ "clumps", "clumps", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[552] = [ "cock", "cock", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[553] = [ "coco", "coco", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[554] = [ "cogs", "cogs", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[555] = [ "column", "column", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[556] = [ "comical", "comical", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[557] = [ "coo", "coo", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[558] = [ "cook", "cook", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[559] = [ "corny", "corny", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[560] = [ "cows", "cows", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[561] = [ "coypu", "coypu", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[562] = [ "criers", "criers", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[563] = [ "crisp", "crisp", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[564] = [ "crock", "crock", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[565] = [ "crotchety", "crotchety", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[566] = [ "cuff", "cuff", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[567] = [ "curb", "curb", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[568] = [ "curry", "curry", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[569] = [ "curtail", "curtail", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[570] = [ "dad", "dad", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[571] = [ "dam", "dam", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[572] = [ "damn", "damn", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[573] = [ "dash", "dash", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[574] = [ "day", "day", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[575] = [ "deaf", "deaf", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[576] = [ "dean", "dean", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[577] = [ "dear", "dear", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[578] = [ "deer", "deer", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[579] = [ "diner", "diner", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[580] = [ "dish", "dish", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[581] = [ "doorways", "doorways", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[582] = [ "dough", "dough", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[583] = [ "duct", "duct", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[584] = [ "dumb", "dumb", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[585] = [ "dust", "dust", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[586] = [ "dyer", "dyer", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[587] = [ "earl", "earl", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[588] = [ "ebb", "ebb", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[589] = [ "ebbs", "ebbs", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[590] = [ "eke", "eke", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[591] = [ "ekes", "ekes", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[592] = [ "elect", "elect", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[593] = [ "elide", "elide", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[594] = [ "elm", "elm", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[595] = [ "em", "em", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[596] = [ "enemies", "enemies", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[597] = [ "entitles", "entitles", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[598] = [ "era", "era", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[599] = [ "erg", "erg", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[600] = [ "errand", "errand", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[601] = [ "errs", "errs", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[602] = [ "eunuchs", "eunuchs", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[603] = [ "every", "every", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[604] = [ "ewe", "ewe", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[605] = [ "ewes", "ewes", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[606] = [ "extend", "extend", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[607] = [ "facet", "facet", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[608] = [ "facto", "facto", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[609] = [ "falls", "falls", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[610] = [ "fan", "fan", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[611] = [ "farad", "farad", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[612] = [ "fat", "fat", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[613] = [ "fatten", "fatten", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[614] = [ "fault", "fault", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[615] = [ "fiber", "fiber", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[616] = [ "filer", "filer", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[617] = [ "filth", "filth", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[618] = [ "finisher", "finisher", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[619] = [ "fir", "fir", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[620] = [ "fix", "fix", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[621] = [ "flam", "flam", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[622] = [ "flea", "flea", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[623] = [ "flee", "flee", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[624] = [ "flu", "flu", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[625] = [ "flue", "flue", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[626] = [ "folder", "folder", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[627] = [ "food", "food", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[628] = [ "fool", "fool", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[629] = [ "foot", "foot", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[630] = [ "for", "for", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[631] = [ "forbid", "forbid", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[632] = [ "four", "four", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[633] = [ "fowl", "fowl", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[634] = [ "fox", "fox", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[635] = [ "frank", "frank", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[636] = [ "freezes", "freezes", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[637] = [ "fro", "fro", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[638] = [ "frogs", "frogs", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[639] = [ "from", "from", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[640] = [ "fur", "fur", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[641] = [ "gad", "gad", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[642] = [ "gag", "gag", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[643] = [ "gap", "gap", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[644] = [ "garb", "garb", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[645] = [ "gas", "gas", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[646] = [ "gauges", "gauges", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[647] = [ "gay", "gay", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[648] = [ "gel", "gel", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[649] = [ "gnu", "gnu", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[650] = [ "go", "go", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[651] = [ "golf", "golf", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[652] = [ "gouge", "gouge", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[653] = [ "grief", "grief", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[654] = [ "hair", "hair", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[655] = [ "harry", "harry", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[656] = [ "hash", "hash", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[657] = [ "hear", "hear", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[658] = [ "heir", "heir", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[659] = [ "hen", "hen", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[660] = [ "hew", "hew", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[661] = [ "hid", "hid", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[662] = [ "high", "high", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[663] = [ "him", "him", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[664] = [ "hip", "hip", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[665] = [ "his", "his", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[666] = [ "hog", "hog", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[667] = [ "hooves", "hooves", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[668] = [ "hop", "hop", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[669] = [ "hopper", "hopper", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[670] = [ "horde", "horde", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[671] = [ "horny", "horny", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[672] = [ "horse", "horse", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[673] = [ "hotly", "hotly", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[674] = [ "hug", "hug", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[675] = [ "hum", "hum", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[676] = [ "humbug", "humbug", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[677] = [ "hurry", "hurry", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[678] = [ "hush", "hush", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[679] = [ "hymn", "hymn", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[680] = [ "ices", "ices", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[681] = [ "idiom", "idiom", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[682] = [ "ill", "ill", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[683] = [ "impair", "impair", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[684] = [ "indices", "indices", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[685] = [ "inhabit", "inhabit", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[686] = [ "inner", "inner", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[687] = [ "inns", "inns", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[688] = [ "is", "is", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[689] = [ "jam", "jam", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[690] = [ "jay", "jay", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[691] = [ "jeeps", "jeeps", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[692] = [ "join", "join", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[693] = [ "joust", "joust", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[694] = [ "junks", "junks", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[695] = [ "kappa", "kappa", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[696] = [ "ken", "ken", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[697] = [ "kern", "kern", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[698] = [ "kid", "kid", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[699] = [ "kitty", "kitty", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[700] = [ "knead", "knead", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[701] = [ "kneads", "kneads", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[702] = [ "kneels", "kneels", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[703] = [ "lab", "lab", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[704] = [ "lain", "lain", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[705] = [ "lair", "lair", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[706] = [ "lamb", "lamb", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[707] = [ "lander", "lander", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[708] = [ "lash", "lash", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[709] = [ "last", "last", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[710] = [ "law", "law", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[711] = [ "lawyer", "lawyer", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[712] = [ "layman", "layman", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[713] = [ "leaf", "leaf", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[714] = [ "lean", "lean", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[715] = [ "leave", "leave", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[716] = [ "leer", "leer", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[717] = [ "lest", "lest", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[718] = [ "lewd", "lewd", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[719] = [ "liar", "liar", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[720] = [ "libel", "libel", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[721] = [ "lien", "lien", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[722] = [ "limb", "limb", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[723] = [ "links", "links", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[724] = [ "lisp", "lisp", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[725] = [ "lisps", "lisps", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[726] = [ "list", "list", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[727] = [ "locks", "locks", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[728] = [ "lockup", "lockup", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[729] = [ "locus", "locus", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[730] = [ "lookup", "lookup", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[731] = [ "lush", "lush", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[732] = [ "lust", "lust", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[733] = [ "macho", "macho", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[734] = [ "macro", "macro", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[735] = [ "mad", "mad", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[736] = [ "madly", "madly", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[737] = [ "maim", "maim", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[738] = [ "mama", "mama", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[739] = [ "mammas", "mammas", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[740] = [ "manly", "manly", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[741] = [ "map", "map", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[742] = [ "marry", "marry", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[743] = [ "mart", "mart", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[744] = [ "me", "me", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[745] = [ "mend", "mend", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[746] = [ "merger", "merger", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[747] = [ "merit", "merit", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[748] = [ "midst", "midst", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[749] = [ "mind", "mind", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[750] = [ "mint", "mint", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[751] = [ "mold", "mold", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[752] = [ "mortem", "mortem", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[753] = [ "moth", "moth", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[754] = [ "mu", "mu", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[755] = [ "mummy", "mummy", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[756] = [ "mural", "mural", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[757] = [ "my", "my", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[758] = [ "nails", "nails", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[759] = [ "net", "net", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[760] = [ "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[761] = [ "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[762] = [ "nimbus", "nimbus", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[763] = [ "ninth", "ninth", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[764] = [ "nip", "nip", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[765] = [ "nod", "nod", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[766] = [ "north", "north", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[767] = [ "notches", "notches", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[768] = [ "noun", "noun", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[769] = [ "oak", "oak", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[770] = [ "oat", "oat", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[771] = [ "obscene", "obscene", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[772] = [ "ode", "ode", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[773] = [ "off", "off", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[774] = [ "oil", "oil", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[775] = [ "oldy", "oldy", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[776] = [ "opt", "opt", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[777] = [ "order", "order", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[778] = [ "our", "our", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[779] = [ "overt", "overt", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[780] = [ "owner", "owner", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[781] = [ "packs", "packs", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[782] = [ "pact", "pact", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[783] = [ "pad", "pad", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[784] = [ "pain", "pain", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[785] = [ "pair", "pair", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[786] = [ "party", "party", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[787] = [ "past", "past", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[788] = [ "pay", "pay", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[789] = [ "pear", "pear", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[790] = [ "peer", "peer", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[791] = [ "per", "per", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[792] = [ "pest", "pest", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[793] = [ "phi", "phi", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[794] = [ "piano", "piano", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[795] = [ "pier", "pier", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[796] = [ "piety", "piety", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[797] = [ "pious", "pious", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[798] = [ "plug", "plug", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[799] = [ "plus", "plus", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[800] = [ "prank", "prank", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[801] = [ "preview", "preview", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[802] = [ "proof", "proof", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[803] = [ "pros", "pros", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[804] = [ "prow", "prow", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[805] = [ "pulls", "pulls", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[806] = [ "pumps", "pumps", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[807] = [ "push", "push", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[808] = [ "qua", "qua", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[809] = [ "quay", "quay", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[810] = [ "ran", "ran", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[811] = [ "range", "range", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[812] = [ "rangy", "rangy", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[813] = [ "rat", "rat", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[814] = [ "raw", "raw", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[815] = [ "recur", "recur", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[816] = [ "remit", "remit", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[817] = [ "resistable", "resistable", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[818] = [ "rinse", "rinse", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[819] = [ "roar", "roar", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[820] = [ "rocket", "rocket", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[821] = [ "root", "root", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[822] = [ "rosebud", "rosebud", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[823] = [ "rowdy", "rowdy", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[824] = [ "rummy", "rummy", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[825] = [ "rural", "rural", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[826] = [ "rustic", "rustic", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[827] = [ "rye", "rye", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[828] = [ "sad", "sad", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[829] = [ "sag", "sag", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[830] = [ "saga", "saga", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[831] = [ "sage", "sage", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[832] = [ "sails", "sails", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[833] = [ "sake", "sake", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[834] = [ "sallies", "sallies", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[835] = [ "sap", "sap", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[836] = [ "say", "say", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[837] = [ "scolds", "scolds", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[838] = [ "scow", "scow", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[839] = [ "scurvy", "scurvy", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[840] = [ "seismic", "seismic", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[841] = [ "sepia", "sepia", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[842] = [ "serf", "serf", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[843] = [ "sewer", "sewer", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[844] = [ "sex", "sex", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[845] = [ "shrill", "shrill", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[847] = [ "sixth", "sixth", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[848] = [ "ski", "ski", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[849] = [ "skull", "skull", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[850] = [ "snail", "snail", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[851] = [ "snows", "snows", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[852] = [ "so", "so", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[853] = [ "sock", "sock", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[854] = [ "solvable", "solvable", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[855] = [ "sorer", "sorer", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[856] = [ "spa", "spa", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[857] = [ "spark", "spark", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[858] = [ "spy", "spy", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[859] = [ "steep", "steep", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[860] = [ "stoop", "stoop", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[861] = [ "strays", "strays", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[862] = [ "strew", "strew", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[863] = [ "stroll", "stroll", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[864] = [ "stumps", "stumps", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[865] = [ "styli", "styli", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[866] = [ "subtasks", "subtasks", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[867] = [ "sunrise", "sunrise", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[868] = [ "surf", "surf", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[869] = [ "tact", "tact", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[870] = [ "tanks", "tanks", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[871] = [ "tasks", "tasks", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[872] = [ "taste", "taste", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[873] = [ "tear", "tear", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[874] = [ "tease", "tease", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[875] = [ "teen", "teen", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[876] = [ "ten", "ten", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[877] = [ "tender", "tender", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[878] = [ "test", "test", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[879] = [ "thaw", "thaw", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[880] = [ "the", "the", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[881] = [ "theorem", "theorem", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[882] = [ "this", "this", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[883] = [ "thorn", "thorn", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[884] = [ "thug", "thug", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[885] = [ "thus", "thus", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[886] = [ "tier", "tier", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[887] = [ "tip", "tip", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[888] = [ "tipper", "tipper", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[889] = [ "toast", "toast", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[890] = [ "top", "top", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[891] = [ "toy", "toy", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[892] = [ "trail", "trail", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[893] = [ "treasures", "treasures", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[894] = [ "tresses", "tresses", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[895] = [ "trier", "trier", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[896] = [ "trolls", "trolls", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[897] = [ "try", "try", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[898] = [ "tug", "tug", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[899] = [ "twang", "twang", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[900] = [ "twins", "twins", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[901] = [ "ugh", "ugh", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[902] = [ "ulcers", "ulcers", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[903] = [ "unfit", "unfit", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[904] = [ "uplift", "uplift", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[905] = [ "urns", "urns", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[906] = [ "us", "us", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[907] = [ "use", "use", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[908] = [ "uses", "uses", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[909] = [ "vanisher", "vanisher", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[910] = [ "vex", "vex", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[911] = [ "video", "video", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[912] = [ "visas", "visas", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[913] = [ "wage", "wage", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[914] = [ "waive", "waive", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[915] = [ "wake", "wake", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[916] = [ "wanly", "wanly", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[917] = [ "warn", "warn", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[918] = [ "we", "we", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[919] = [ "web", "web", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[920] = [ "wee", "wee", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[921] = [ "wet", "wet", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[922] = [ "wetly", "wetly", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[923] = [ "wheels", "wheels", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[924] = [ "whisk", "whisk", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[925] = [ "wields", "wields", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[926] = [ "wig", "wig", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[927] = [ "windy", "windy", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[928] = [ "wise", "wise", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[929] = [ "wolf", "wolf", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[930] = [ "wolves", "wolves", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[931] = [ "woos", "woos", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[932] = [ "wordy", "wordy", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[933] = [ "worse", "worse", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[934] = [ "worst", "worst", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[935] = [ "wreck", "wreck", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[936] = [ "yanks", "yanks", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[937] = [ "yard", "yard", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];
patternArray[938] = [ "yeast", "yeast", "Normal", 0.17, 0.50, "Chunky Kibbles <chunky@icculus.org>" ];

export { patternArray, groups };