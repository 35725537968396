import { useState, useEffect } from "react";
import { Siteswap } from "../simulator/Siteswap";
import { Pattern } from "../simulator/Pattern";
import { GetDwellPaths } from "../simulator/DwellPath";
import ErrorBoundary from "../../util/ErrorBoundary";
import Viewport from "./Viewport";

export default function GunswapAnimation({ site }) {
    const patternSettings = {
        siteswap: "51",
        beatDuration: 0.24,
        dwellPath: "(30)(10)",
        dwellRatio: 0.8,
    };
    const [siteswap, setSiteswap] = useState(new Siteswap("3"));
    const [pattern, setPattern] = useState(
        new Pattern(
            siteswap,
            GetDwellPaths(patternSettings.dwellPath),
            patternSettings.dwellRatio,
            1
        )
    );

    pattern.Simulate(100, patternSettings.beatDuration);

    useEffect(() => {
        try {
            const newSite = new Siteswap(site);
            const newPat = new Pattern(
                newSite,
                GetDwellPaths(patternSettings.dwellPath),
                patternSettings.dwellRatio,
                1
            );
            newPat.Simulate(100, patternSettings.beatDuration);
            setPattern(newPat);
            setSiteswap(newSite);
        } catch (e) {
            console.log(e);
        }
    }, [site]);

    return (
        <div style={{ height: "500px" }}>
            <ErrorBoundary>
                {pattern.Simulation && (
                    <Viewport pattern={pattern} />
                )}
            </ErrorBoundary>
        </div>
    );
}
