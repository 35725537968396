import {  useState } from "react";
import { FindSiteswaps, FindSiteswapsConfig } from "../search/Search";
import {
    List,
    Stack,
    ListItem,
    Button,
    Box,
    TextField,
    Switch,
    FormControlLabel,
} from "@mui/material";
import { Siteswap } from "../simulator/Siteswap";
import { Pattern } from "../simulator/Pattern";
import { GetDwellPaths } from "../simulator/DwellPath";
import { PatternSettings } from "./PatternSettings";

interface Props {
    updatePattern: Function;
}

export function Search(props: Props) {
    const [siteswaps, setSiteswaps] = useState([]);

    const [numProps, setNumProps] = useState("3");
    const [minPeriod, setMinPeriod] = useState("1");
    const [maxPeriod, setMaxPeriod] = useState("5");
    const [includeMultiplex, setIncludeMultiplex] = useState(false);
    const [includeExcited, setIncludeExcited] = useState(true);
    const [maxSearches, setMaxSearches] = useState("1000");
    const [maxSiteswaps, setMaxSiteswaps] = useState("100");
    const [includeSynchronous, setIncludeSynchronous] = useState(true);
    const [exclude, setExclude] = useState("");

    const findSiteswaps = () => {
        const config: FindSiteswapsConfig | undefined = {
            numProps: parseInt(numProps),
            minPeriod: parseInt(minPeriod),
            maxPeriod: parseInt(maxPeriod),
            includeMultiplex,
            includeExcited,
            maxSearches: parseInt(maxSearches),
            maxSiteswaps: parseInt(maxSiteswaps),
            sync: includeSynchronous,
            exclude: exclude.split(",")
        }

        if (config) {
            setSiteswaps(FindSiteswaps(config));
        }
    };

    const juggleSiteswap = (siteswap) => {
        const patternSettings: PatternSettings = {
            siteswap: siteswap,
            dwellPath: "(30)(10)",
            beatDuration: 0.24,
            dwellRatio: 0.8,
        };

        var s = new Siteswap(patternSettings.siteswap);
        var pattern = new Pattern(
            s,
            GetDwellPaths(patternSettings.dwellPath),
            patternSettings.dwellRatio,
            1
        );
        pattern.Simulate(100, patternSettings.beatDuration);

        props.updatePattern(pattern, patternSettings);
    };

    return (
        <Stack>
            <Box component="form"
                sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, }} >
                <TextField
                    label='Number of props'
                    type='number'
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    value={numProps}
                    onChange={(event) => setNumProps(event.target.value)} />

                <TextField
                    label='Minimum period'
                    type='number'
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    value={minPeriod}
                    onChange={(event) => setMinPeriod(event.target.value)} />

                <TextField
                    label='Maximum period'
                    type='number'
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    value={maxPeriod}
                    onChange={(event) => setMaxPeriod(event.target.value)} />
                <FormControlLabel
                    control={<Switch checked={includeMultiplex}
                        onChange={(event) => setIncludeMultiplex(event.target.checked)} />}
                    label="Include multiplex" />
                <FormControlLabel
                    control={<Switch checked={includeExcited}
                        onChange={(event) => setIncludeExcited(event.target.checked)} />}
                    label="Include excited" />

                <TextField
                    label='Maximum searches'
                    type='number'
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    value={maxSearches}
                    onChange={(event) => setMaxSearches(event.target.value)} />
                <TextField
                    label='Maximum siteswaps'
                    type='number'
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    value={maxSiteswaps}
                    onChange={(event) => setMaxSiteswaps(event.target.value)} />

                <FormControlLabel
                    control={<Switch checked={includeSynchronous}
                        onChange={(event) => setIncludeSynchronous(event.target.checked)} />}
                    label="Include synchronous" />

                <TextField
                    label='Exclude'
                    value={exclude}
                    onChange={(event) => setExclude(event.target.value)} />

            </Box>

            <Button
                variant="contained"
                onClick={findSiteswaps}>
                Find Siteswaps
            </Button>

            <List className="siteswap-list" >
                {siteswaps.map((s, i) => (
                    <ListItem key={i!.toString()}>
                        <Button
                            variant="outlined"
                            onClick={(event) => juggleSiteswap(s as string)}
                            >
                            {s}
                        </Button>
                    </ListItem>
                ))}

            </List>
        </Stack>
    );
}
