// https://stackoverflow.com/questions/19235286/convert-html5-canvas-sequence-to-a-video-file
export function recordCanvas(canvas: HTMLCanvasElement, time, fps: number) {
   const recordedChunks = [];
    return new Promise(function (res, rej) {
        const stream = canvas.captureStream(fps)
        // https://developer.mozilla.org/en-US/docs/Web/Media/Formats/codecs_parameter
        const mediaRecorder = new MediaRecorder(stream, {
            mimeType: "video/webm; codecs=vp8"
        });
        mediaRecorder.start(time || 4000);

        mediaRecorder.ondataavailable = function (event) {
            recordedChunks.push(event.data);
            if (mediaRecorder.state === 'recording') {
                mediaRecorder.stop();
            }
        }

        mediaRecorder.onstop = function (event) {
            const blob = new Blob(recordedChunks, {type: "video/webm"});
            const url = URL.createObjectURL(blob);
            res(url);
        }
    })
}

export function recordCanvasStop(canvas: HTMLCanvasElement, fps: number) {
    const stream = canvas.captureStream(fps)
    // https://developer.mozilla.org/en-US/docs/Web/Media/Formats/codecs_parameter
    const mediaRecorder = new MediaRecorder(stream, {
        mimeType: "video/webm; codecs=vp8"
    });

    return mediaRecorder;
}
