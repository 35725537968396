import {  useEffect, useState } from "react";
import { Siteswap } from "../simulator/Siteswap";
import { Pattern } from "../simulator/Pattern";
import { GetDwellPaths } from "../simulator/DwellPath";
import {  Typography, Grid, Stack } from "@mui/material";
import {  PatternSettings } from "./PatternSettings";
import Viewport from "./Viewport";
import { Search } from "./Search";
import { JugglingDiagram } from "../../components/graphs/Ladder";
import { defaultPatternSettings } from "./Defaults";

export default function PatternGenerator(props) {
    const [pattern, setPattern] = useState(new Pattern(
        new Siteswap(defaultPatternSettings.siteswap),
        GetDwellPaths(defaultPatternSettings.dwellPath),
        defaultPatternSettings.dwellRatio,
        0
    ));
    const [patternSettings, setPatternSettings] = useState(defaultPatternSettings);

    useEffect(() => {
        pattern.Simulate(100, 0.24);
    }, [pattern]);
    return (
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Stack>
                        <Typography variant="h4">
                            Pattern Generator
                        </Typography>
                        <Search updatePattern={setPattern}></Search>
                    </Stack>
                </Grid>
                <Grid item xs={8}>
                   <Viewport pattern={pattern} />
                   <JugglingDiagram sequence={patternSettings.siteswap} />
                </Grid>
            </Grid>
    )
 }
