import { useEffect, useState } from "react";
import { Siteswap } from "../simulator/Siteswap";
import { Pattern } from "../simulator/Pattern";
import { GetDwellPaths } from "../simulator/DwellPath";
import {
  Button,
  Stack,
  Alert,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import CasinoIcon from '@mui/icons-material/Casino';
import { FindRandomPattern } from "../search/RandomPattern";
import { examplePatterns, ExamplePatternsList } from "./ExamplePatternsList";
import DwellPathInput from "./DwellPathInput";
import { defaultPatternSettings } from "./Defaults";

interface Props {
  updatePattern: Function;
  patternSettings: PatternSettings;
}

export interface PatternSettings {
  siteswap: string;
  beatDuration: number;
  dwellPath: string;
  dwellRatio: number;
}

export function PatternSettingsControls({PREsettings, updatePattern}) {
    const [settings, setSettings] = useState<PatternSettings>(PREsettings);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    useEffect(() => {
    try {
      const siteswap = new Siteswap(settings.siteswap.toString());
      const pattern = new Pattern(
        siteswap,
        GetDwellPaths(settings.dwellPath),
        settings.dwellRatio,
        1
      );
      pattern.Simulate(100, settings.beatDuration);
      // lift pattern w/ simulation up to parent
      updatePattern(pattern, settings);
      setErrorMessage(null);
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message);
      } else if (typeof e === "string") {
        // fallback in case any exceptions were thrown as strings
        setErrorMessage(e);
      }
    }
    }, [settings]);


  const juggleRandomPattern = () => {
    const patternSettings = FindRandomPattern();
    setSettings(patternSettings);
  }

  const juggleExamplePattern = (
    pattern: Pattern,
    patternSettings: PatternSettings
  ) => {
    setSettings(patternSettings);
    updatePattern(pattern, patternSettings);
  }

    const onSiteswapChange = (event) => {
        setSettings({
            ...settings,
            siteswap: event.target.value,
        })
    }

    const onDwellPathChange = (event) => {
        setSettings({
            ...settings,
            dwellPath: event.target.value,
        })
    }

    return (
        <>
            <Typography variant="h4">
                Pattern Editor
            </Typography>
            <Stack>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                >
                    <ExamplePatternsList updatePattern={juggleExamplePattern} />
                    <SiteswapInput value={settings.siteswap}
                                   onChange={onSiteswapChange}/>
                    <DwellPathInput
                    value={settings.dwellPath}
                    onChange={onDwellPathChange}
                    />
                    <TextField
                        label='Beat Duration'
                        value={settings.beatDuration}
                        type='number'
                        onChange={(event) => {
                            setSettings({
                                ...settings,
                                beatDuration: parseFloat(event.target.value),
                            })
                        }} />
                    <TextField
                        label='Dwell Ratio'
                        type='number'
                        value={settings.dwellRatio}
                        onChange={(event) => {
                            setSettings({
                                ...settings,
                                dwellRatio: parseFloat(event.target.value),
                            })
                        }} />

                    <Stack direction="row">
                        <Button
                            startIcon={<CasinoIcon />}
                            variant="contained"
                            className="panel-main-button"
                            onClick={juggleRandomPattern}
                        >Random Pattern </Button>
                    </Stack>
                    {errorMessage && (
                        <Alert severity="error" >
                            {errorMessage}
                        </Alert>
                    )}
                </Box>
            </Stack>
        </>
    );
}

export function SiteswapInput({value, onChange}) {
    /*     const [siteswap, setSiteswap] = useState<string>(value); */

    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        try {
            const siteswap = new Siteswap(value);
            const pattern = new Pattern(
                siteswap,
                GetDwellPaths(defaultPatternSettings.dwellPath),
                defaultPatternSettings.dwellRatio,
                1
            );
            pattern.Simulate(100, defaultPatternSettings.beatDuration);
            setError(null);
        } catch (e) {
            if (e instanceof Error) {
                setError(e.message);
            } else if (typeof e === "string") {
                // fallback in case any exceptions were thrown as strings
                setError(e);
            }
        }
    }, [value]);


    return (
        <TextField
            error={error !== null}
            color='success'
            helperText={error}
            label='Siteswap'
            value={value}
            onChange={onChange}
        />
    )
}
