import {
    List,
    ListItem,
    Link,
    TextField,
    MenuItem,
} from "@mui/material";
import { Siteswap } from "../simulator/Siteswap";
import { Pattern } from "../simulator/Pattern";
import { GetDwellPaths } from "../simulator/DwellPath";
import { PatternSettings } from "./PatternSettings";
import { useState } from "react";

interface Props {
  updatePattern: Function;
}

interface ExamplePattern {
  name: string;
  patternSettings: PatternSettings;
}
export const examplePatterns = [
    {
        name: "Cascade",
        patternSettings: {
            siteswap: "3",
            dwellPath: "(30)(10)",
            beatDuration: 0.24,
            dwellRatio: 0.8,
        },
    },
    {
        name: "Reverse Cascade",
        patternSettings: {
            siteswap: "3",
            dwellPath: "(10)(30)",
            beatDuration: 0.24,
            dwellRatio: 0.8,
        },
    },
    {
        name: "Columns",
        patternSettings: {
            siteswap: "(4,4)(4,0)",
            dwellPath: "(20).(20).(0)",
            beatDuration: 0.24,
            dwellRatio: 0.8,
        },
    },
    {
        name: "Yo-Yo",
        patternSettings: {
            siteswap: "(4,2)",
            dwellPath: "(20).(0,60).(0).(0,25)",
            beatDuration: 0.2,
            dwellRatio: 1.1,
        },
    },
    {
        name: "Tennis",
        patternSettings: {
            siteswap: "3",
            dwellPath: "(35,15).(20)(7).(20)(7)",
            beatDuration: 0.24,
            dwellRatio: 0.9,
        },
    },
    {
        name: "423",
        patternSettings: {
            siteswap: "423",
            dwellPath: "(30).(30).(30)(10)",
            beatDuration: 0.24,
            dwellRatio: 0.8,
        },
    },
    {
        name: "Factory",
        patternSettings: {
            siteswap: "(2,4)(2,4x)(2x,4)",
            dwellPath: "(20).(20).(30,40).(0).(-20,40).(30)(0)",
            beatDuration: 0.24,
            dwellRatio: 1.2,
        },
    },
    {
        name: "Mill's Mess",
        patternSettings: {
            siteswap: "3",
            dwellPath: "(0,10)(-20,10).(0)(20).(0,0)(-20,0)",
            beatDuration: 0.24,
            dwellRatio: 1,
        },
    },
];
interface ExamplePattern {
  name: string;
  patternSettings: PatternSettings;
}

export function ExamplePatternsList(props: Props) {
    const [pattern, setPattern] = useState<string>('Cascade');

    const jugglePattern = (patternSettings: PatternSettings) => {
        var s = new Siteswap(patternSettings.siteswap);
        var pattern = new Pattern(
            s,
            GetDwellPaths(patternSettings.dwellPath),
            patternSettings.dwellRatio,
            1
        );
        pattern.Simulate(100, patternSettings.beatDuration);

        props.updatePattern(pattern, patternSettings);
    };

    return (
        <TextField
          id="select-example-pattern"
          select
          label="Example Patterns"
          value={pattern}
          onChange={(event) => {
            setPattern(event.target.value);
            jugglePattern(JSON.parse(event.target.value));
          }}
        >
            {examplePatterns.map((item: ExamplePattern, i) => (
              <MenuItem
                key={i}
                value={JSON.stringify(item.patternSettings)}
                >
                {item.name}
              </MenuItem>
            ))}

        </TextField>
    );
}
