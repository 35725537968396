import SelectDwellPath from "./SelectDwellPath";
import {
    TextField,
    Switch,
    Typography,
    Stack,
} from "@mui/material";
import { useState } from "react";

export default function DwellPathInput({value, onChange}) {
    const [manual, setManual] = useState<boolean>(false);

    const input = manual
                    ? <SelectDwellPath
                        value={value}
                        onChange={onChange}/>
                    : <TextField
                        label='Dwellpath'
                        value={value}
                        onChange={onChange} />;

    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography>Manual</Typography>
                <Switch
                    defaultChecked
                    checked={manual}
                    inputProps={{ 'aria-label': 'controlled' }}
                    onChange={()=>setManual(!manual)}
                />
                <Typography>Select</Typography>
            </Stack>
            {input}
        </>
    )
}
