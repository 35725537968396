import { useEffect } from "react";
import JMLib from '../../lib/jmlib';

export default function JML ({site, style, pattern, pattNo, ctx, setAnimation, status}) {
    const jmlib = new JMLib();

    useEffect( () => {
        jmlib.stopJuggle()
        if (pattern) {
            jmlib.setPattern(pattern);
        } else if (pattNo) {
            jmlib.setPatternEx(pattNo);
        } else if (site){
            jmlib.set_patt(site);
        }
        if (style) {
            jmlib.setStyle(style);
        }
        jmlib.setWindowSize(500, 500); // TODO not hardcoded
        jmlib.setScalingMethod(JMLib.SCALING_METHOD_DYNAMIC);
        jmlib.startJuggle();
        const interval = setInterval(juggleStep, 50);
        return () => clearInterval(interval);

    }, [site, style, pattern, pattNo]);

    function juggleStep() {
        jmlib.doJuggle();
        const {arm, handBall1, hand1, handBall2, hand2, ballRadius, ballNumber, balls} = jmlib;
        setAnimation({ arm, handBall1, hand1, handBall2, hand2, ballRadius, ballNumber, balls});
    }
    return null;
}