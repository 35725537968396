import { Grid } from "@mui/material";
import JuggleMaster from "../components/animation/Jmlib";
import { TestD3 } from "../components/graphs/Ladder";

export default function Home() {
    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <JuggleMaster site="531" />
            </Grid>
            <Grid item xs={4}>
                <TestD3 />
            </Grid>
        </Grid>
    )

}
