import styleArray from './styleData';

class Style {
    constructor(name, length, data) {
        this.name = name;
        this.length = length;
        this.data = data;
    }
}

const styles = new Map();
for (const [name, [length, data]] of Object.entries(styleArray)) {
    styles.set(name, new Style(name, length, data));
}

export default styles;
