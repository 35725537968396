// @ts-nocheck
import { useEffect, useState, useRef } from "react";
import { GraphData, LinkObject, NodeObject } from "force-graph";
import { ForceGraph2D } from "react-force-graph";
import { Grid, Box, Alert, TextField } from "@mui/material";
import GunswapAnimation from "../gunswap/ui/GunswapAnimation";
import { withTheme } from '@mui/styles';
import { purple, green, blue, red, yellow, orange, cyan, pink } from '@mui/material/colors';

type StateNode = NodeObject & { label: string };
type StateLink = LinkObject & { label: string; curvature: number };

export function GraphWithAnim() {
    const [site, setSite] = useState();
    const [balls, setBalls] = useState(3);
    const [height, setHeight] = useState(5);
    const [error, setError] = useState("");

    return (
            <Grid container>
                <Grid item xs={2}>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                    >
                        <TextField
                            label='Number of balls'
                            type='number'
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            value={balls}
                            onChange={(event) => setBalls(event.target.value)} />
                        <TextField
                            label='Height of pattern'
                            type='number'
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            value={height}
                            onChange={(event) => setHeight(event.target.value)} />
                    </Box>
                    {site && <Alert severity="success">You selected the siteswap: {site}</Alert>}
                    {error && <Alert severity="error"> {error} </Alert>}
                </Grid>
                <Grid item xs={4}>
                    <GraphView
                        balls={balls}
                        height={height}
                        setError={setError}
                        setSite={setSite}
                    />
                </Grid>
                <Grid item xs={4}>
                    <GunswapAnimation site={site} />
                </Grid>
            </Grid>
    );
}

export const GraphView = withTheme((props) => <GraphViewWrapped {...props} />);

function GraphViewWrapped({ balls, height, setError, setSite, theme }) {
    const [graphData, setGraphData] = useState<GraphData | undefined>(undefined);
    const fgRef = useRef(null);
    const [highlightNodes, setHighlightNodes] = useState(new Set());
    const [highlightLinks, setHighlightLinks] = useState(new Set());
    const [hoverNode, setHoverNode] = useState(null);

    useEffect(() => {
        setGraphData(computeStates(parseInt(balls), parseInt(height)));
    }, [balls, height]);
    /*
          nodeLabel="id"
            onEngineStop={() => fgRef.current.zoomToFit(400)}

        */
    const updateHighlights = () => {
        setHighlightLinks(highlightLinks);
        setHighlightNodes(highlightNodes);
    };

    const handleLinkClick = (link) => {
        if (highlightLinks.has(link)) {
            highlightLinks.delete(link);
        } else {
            highlightLinks.add(link);
        }
        updateHighlights();
        getSiteSwap();
    };

    const getSiteSwap = () => {
        let maxLink = null;
        let maxValue = -1;

        const t2l = new Map();
        const s2l = new Map();

        highlightLinks.forEach((link, i) => {
            t2l.set(link.target.id, link);
            s2l.set(link.source.id, link);

            if (link.label > maxValue) {
                maxLink = link;
                maxValue = link.label;
            }
        });
        // reconstruct it from the maximum

        const pattern = [];
        let current = maxLink;
        for (let i = 0; i < t2l.size - 1; i++) {
            let nextLink = s2l.get(current.target.id);
            console.log("CurrentLink: ", current, " ,NextLink:", nextLink);
            if (nextLink) {
                pattern.push(current.label);
                current = nextLink;
            } else {
                console.log("invalid");
            }
        }
        if (current && current.target.id === maxLink.source.id) {
            console.log("valid");
            pattern.push(current.label);
            setSite(pattern.join(""));
        } else {
            setSite("");
        }
        console.log(pattern);
    };

    function enumerateStates(balls: number, height: number) {
        // convert to int
        //  0 <= b
        // b <= h
        // 1 <= h
        // Do this more efficiently?
        setError("");
        if (balls < 0) return setError("Negative number of balls");
        if (balls > height) return setError("More balls than you can throw");
        if (height < 1) return setError("Height is too small");
        if (balls > 20) return setError("Too many balls");
        if (height > 20) return setError("Throws too high");
        if (!Number.isInteger(balls) || !Number.isInteger(height))
            return setError("Height and Balls need to be integers");
        const rootState: BitState = new Array<Bit>(height);
        const nodes = [];

        function recursiveEnumeration(
            balls: number,
            height: number,
            index: number,
            state: BitState
        ) {
            if (index === height) {
                if (balls == 0) {
                    nodes.push({ id: state.join("") });
                }
                return;
            }
            state[index] = 1;
            recursiveEnumeration(balls - 1, height, index + 1, state);
            state[index] = 0;
            recursiveEnumeration(balls, height, index + 1, state);
        }
        recursiveEnumeration(balls, height, 0, rootState);
        return nodes;
    }

    function computeStates(balls: number, height: number) {
        const nodes = enumerateStates(balls, height);
        if (!nodes) return;
        const links = [];

        nodes.forEach((node) => {
            const vertex: string = node.id;
            if (vertex.startsWith("0")) {
                const nextNode = vertex.substring(1) + "0";
                links.push({
                    source: vertex,
                    target: nextNode,
                    label: 0,
                });
            } else {
                let tempNode = vertex + "0";
                for (let t = 1; t <= height; t++) {
                    if (tempNode[t] == 0) {
                        const nextState =
                            tempNode.substring(1, t) +
                            "1" +
                            tempNode.substring(t + 1, height + 1);
                        if (nextState === vertex) {
                            console.log(vertex);
                            links.push({
                                source: vertex,
                                target: nextState,
                                label: t,
                                curvature: 0.5,
                                value: 5,
                            });
                        } else {
                            links.push({
                                source: vertex,
                                target: nextState,
                                label: t,
                                curvature: 0.1,
                            });
                        }
                    }
                }
            }
        });

        return {
            nodes: nodes,
            links: links,
        };
    }
    return (
        <ForceGraph2D
            ref={fgRef}
            linkDirectionalArrowLength={5}
            linkLabel={(link) => (link as StateLink).label}
            linkCurvature={(link) => (link as StateLink).curvature}
            linkDirectionalArrowRelPos={0.5}
            linkWidth={(link) => (highlightLinks.has(link) ? 4 : 2)}
            linkDirectionalParticles={4}
            linkDirectionalParticleWidth={(link) =>
                highlightLinks.has(link) ? 4 : 0
            }
            nodeLabel="id"
            nodeColor={(node) => theme.palette.grey[500]}
            nodeOpacity={1.0}
            onLinkClick={handleLinkClick}
            linkColor={(link) => {
                const label = (link as StateLink).label;
                switch (label % 8) {
                    case 0:
                        return blue[500];
                    case 1:
                        return yellow[500];
                    case 2:
                        return cyan[500];
                    case 3:
                        return green[500];
                    case 4:
                        return orange[500];
                    case 5:
                        return red[500];
                    case 6:
                        return purple[500];
                    case 7:
                        return pink[500];
                    default:
                        return theme.palette.primary.main;
                }
            }}
            graphData={graphData}
        />
    );
}

/*
 * How to compute the graph?
 * - start with a vertex and go on from there
 * - compute all vertices and edges directly
 */

type Bit = 0 | 1;
type BitState = [...Bit[]];
